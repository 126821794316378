import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, FormGroup, FormControlLabel, Checkbox, Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import ElementLabel from "../../Translation/ElementLabel";
import CategoryLabel from "../../Translation/CategoryLabel";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";
import DemographicOptionLabel from "../../Translation/DemographicOptionLabel";


const useStyles = makeStyles((theme) => ({
    label: {
        marginLeft: 0
    },
}));

export default function ElementHotspots(props) {
    const classes = useStyles();
    const [categories, setCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true
    });

    // Determine colors for report based on type
    const categoryColors = props.surveyData && props.surveyData.attributes && props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;

    const toggleCategory = (category) => {
        // Don't allow the last category to be deselected
        const enabledCategories = Object.keys(categories).filter((current) => {
            return categories[current];
        });
        if (1 === enabledCategories.length && categories[category]) {
            return;
        }
        setCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    if (!props.reportData.data) {
        return null;
    }

    const demCell = (dems) => {
        const label = [];
        props.demographicQuestions.forEach((demQ, i) => {
            if (demQ.attributes.demographic in dems) {
                demQ.attributes.options.forEach((option) => {
                    if (dems[demQ.attributes.demographic] === option.attributes.value) {
                        label.push(<DemographicOptionLabel key={i} option={option.attributes.title} translations={option.attributes.translations} demographic={demQ.attributes.demographic} demographicOptionLabels={props.demographicOptionLabels} />)
                        label.push(<strong key={'sep'+i}> | </strong>);
                    }
                })
            }
        });

        // Remove last separator
        label.pop();
        return label;
    };

    const top = props.reportData.data.all.filter(ele => categories[ele.category]).slice(0,50);
    const bottom = props.reportData.data.all.slice().reverse().filter(ele => categories[ele.category]).slice(0,50);

    return (
        <Box pt={2}>
            <div ref={props.setScreenshotRef}>
                <FormGroup row style={{justifyContent: 'center'}}>
                    <FormControlLabel
                        className={classes.label}
                        label={<CategoryLabel category="people"/>}
                        style={{backgroundColor: categoryColors['people'].light, borderLeft: '5px solid '+categoryColors['people'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.people}
                                onChange={() => toggleCategory('people')}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<CategoryLabel category="process"/>}
                        style={{backgroundColor: categoryColors['process'].light, borderLeft: '5px solid '+categoryColors['process'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.process}
                                onChange={() => toggleCategory('process')}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<CategoryLabel category="purpose"/>}
                        style={{backgroundColor: categoryColors['purpose'].light, borderLeft: '5px solid '+categoryColors['purpose'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.purpose}
                                onChange={() => toggleCategory('purpose')}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<CategoryLabel category="proactivity"/>}
                        style={{backgroundColor: categoryColors['proactivity'].light, borderLeft: '5px solid '+categoryColors['proactivity'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.proactivity}
                                onChange={() => toggleCategory('proactivity')}
                            />
                        }
                    />
                </FormGroup>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="elementHotspotsReport.highestDemographicCombinations" defaultMessage={"Highest Demographic Combinations"} />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                                </TableCell>
                                <TableCell align="center">
                                    <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {top.map((entity, index) => {
                                if (!categories[entity.category]) {
                                    return null;
                                }
                                return (
                                    <TableRow key={index} style={{backgroundColor: categoryColors[entity.category].light, borderBottom: '10px solid white'}}>
                                        <TableCell style={{borderLeft: '5px solid '+categoryColors[entity.category].dark}}>
                                            {demCell(entity.demographics)}
                                        </TableCell>
                                        <TableCell>
                                            <ElementLabel element={entity.element} />
                                        </TableCell>
                                        <TableCell align="center" style={{fontWeight: 'bold'}}><span title={entity.score}>{Decimal(entity.score).toFixed(1)}</span></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', marginTop: 24, marginBottom: 24}}></div>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="elementHotspotsReport.lowestDemographicCombinations" defaultMessage={"Lowest Demographic Combinations"} />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                                </TableCell>
                                <TableCell align="center">
                                    <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bottom.map((entity, index) => {
                                if (!categories[entity.category]) {
                                    return null;
                                }
                                return (
                                    <TableRow key={index} style={{backgroundColor: categoryColors[entity.category].light, borderBottom: '10px solid white'}}>
                                        <TableCell style={{borderLeft: '5px solid '+categoryColors[entity.category].dark}}>
                                            {demCell(entity.demographics)}
                                        </TableCell>
                                        <TableCell>
                                            <ElementLabel element={entity.element} />
                                        </TableCell>
                                        <TableCell align="center" style={{fontWeight: 'bold'}}><span title={entity.score}>{Decimal(entity.score).toFixed(1)}</span></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
}
