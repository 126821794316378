import axios from 'axios';
import BaseService from './BaseService';

class JobService extends BaseService {

    jobResult(uuid) {
        return axios.get(this.apiUrl('/job-results/'+uuid), {
            headers: this.getAuthHeader(),
        });
    }
}

export default new JobService();
