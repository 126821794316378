import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TableSortLabel, FormGroup, FormControlLabel, Checkbox, makeStyles } from "@material-ui/core";
import { Fragment, useState } from "react";
import Alert from '@material-ui/lab/Alert';
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import FilterListIcon from '@material-ui/icons/FilterList';
import CategoryLabel from "../../Translation/CategoryLabel";
import ElementLabel from "../../Translation/ElementLabel";
import Decimal from 'decimal.js';
import { FormattedMessage } from "react-intl";
import CompareArrow from "./Common/CompareArrow";
import DemographicLabel from "../../Translation/DemographicLabel";

const useStyles = makeStyles(() => ({
    label: {
        marginLeft: 0
    },
}));

export default function ElementRanges(props) {
    const classes = useStyles();
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('diff');
    const [categories, setCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true
    });

    // Determine colors for report based on type
    const categoryColors = props.surveyData && props.surveyData.attributes && props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;

    const toggleSort = (col) => {
        setSortColumn(col);
        // Toggle direction
        setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };

    const toggleCategory = (category) => {
        // Don't allow the last category to be deselected
        const enabledCategories = Object.keys(categories).filter((current) => {
            return categories[current];
        });
        if (1 === enabledCategories.length && categories[category]) {
            return;
        }
        setCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    if (!props.reportData.data) {
        return null;
    }

    if (props.reportData.data.length === 0) {
        if (props.filter && props.filter.site && props.filter.site.length === 1) {
            return (<Alert style={{justifyContent: 'center'}} severity="info" variant="filled">
                <FormattedMessage id="elementRanges.selectAdditionalSites" defaultMessage="Please select additional site(s)" />
            </Alert>);
        } else {
            return (<Alert style={{justifyContent: 'center'}} severity="info" variant="filled">
                <FormattedMessage id="generic.noData" defaultMessage="No data..." />
            </Alert>);
        }

    }

    const sortedData = () => {
        const dataCopy = JSON.parse(JSON.stringify(props.reportData.data ? props.reportData.data : []));

        if ('asc' === sortOrder) {
            dataCopy.sort((a, b) => parseFloat(a[sortColumn]) > parseFloat(b[sortColumn]) ? 1 : -1);
        } else {
            dataCopy.sort((a, b) => parseFloat(a[sortColumn]) > parseFloat(b[sortColumn]) ? 1 : -1).reverse();
        }
        return dataCopy;
    }

    return (
        <div ref={props.setScreenshotRef}>
            <FormGroup row style={{justifyContent: 'center'}}>
                <FormControlLabel
                    className={classes.label}
                    label={<CategoryLabel category="people"/>}
                    style={{backgroundColor: categoryColors['people'].light, borderLeft: '5px solid '+categoryColors['people'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.people}
                            onChange={() => toggleCategory('people')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="process"/>}
                    style={{backgroundColor: categoryColors['process'].light, borderLeft: '5px solid '+categoryColors['process'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.process}
                            onChange={() => toggleCategory('process')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="purpose"/>}
                    style={{backgroundColor: categoryColors['purpose'].light, borderLeft: '5px solid '+categoryColors['purpose'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.purpose}
                            onChange={() => toggleCategory('purpose')}
                        />
                    }
                />
                <FormControlLabel
                    label={<CategoryLabel category="proactivity"/>}
                    style={{backgroundColor: categoryColors['proactivity'].light, borderLeft: '5px solid '+categoryColors['proactivity'].dark, paddingRight: '10px'}}
                    control={
                        <Checkbox
                            color="primary"
                            style={{ padding: '5px' }}
                            checked={categories.proactivity}
                            onChange={() => toggleCategory('proactivity')}
                        />
                    }
                />
            </FormGroup>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                            </TableCell>
                            <TableCell>
                                <FormattedMessage id="generic.site" defaultMessage={"Site"} />
                                {props.demographic ?
                                    <Fragment> | <DemographicLabel demographic={props.demographic} /></Fragment>
                                : null}
                            </TableCell>
                            <TableCell align="center">
                                <FormattedMessage id="generic.range" defaultMessage={"Range"} />
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortColumn === 'diff'}
                                    direction={(sortColumn === 'diff' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('diff')}}
                                >
                                    <FormattedMessage id="generic.difference" defaultMessage={"Difference"}/>
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                                <TableSortLabel
                                    IconComponent={FilterListIcon}
                                    active={sortColumn === 'score'}
                                    direction={(sortColumn === 'score' ? sortOrder : 'desc')}
                                    onClick={() => {toggleSort('score')}}
                                >
                                    <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedData().map((entity, index) => {
                            if (!categories[entity.category]) {
                                return null;
                            }

                            return (
                                <Fragment key={index}>
                                    <TableRow style={{backgroundColor: categoryColors[entity.category].light, borderBottom: '2px solid white'}}>
                                        <TableCell style={{borderLeft: '5px solid '+categoryColors[entity.category].dark, width: '40%'}} rowSpan={2}>
                                            <ElementLabel element={entity.element} />
                                        </TableCell>
                                        <TableCell>
                                            {entity.maxSite}
                                            {Object.keys(entity.maxDemographics).map(d => (
                                                d !== 'site' ? <span key={d}> | {entity.maxDemographics[d]}</span> : null
                                            ))}
                                        </TableCell>
                                        <TableCell align="center" style={{paddingRight:0}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <span title={entity.maxScore}>
                                                    {Decimal(entity.maxScore).toFixed(0)}
                                                </span>
                                                <CompareArrow change={100} />
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            <span title={entity.diff}>{Decimal(entity.diff).toFixed(1)}</span>
                                        </TableCell>
                                        <TableCell align="center" rowSpan={2}>
                                            <span title={entity.score ? entity.score : ''} style={{fontWeight: 'bold'}}>{entity.score ? Decimal(entity.score).toFixed(1) : '-'}</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow style={{backgroundColor: categoryColors[entity.category].light, borderBottom: '10px solid white'}}>
                                        <TableCell>
                                            {entity.minSite}
                                            {Object.keys(entity.minDemographics).map(d => (
                                                d !== 'site' ? <span key={d}> | {entity.minDemographics[d]}</span> : null
                                            ))}
                                        </TableCell>
                                        <TableCell align="center" style={{paddingRight:0}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                <span title={entity.minScore}>
                                                    {Decimal(entity.minScore).toFixed(0)}
                                                </span>
                                                <CompareArrow change={-100} />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
