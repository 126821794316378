import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Grid, IconButton, Link, makeStyles, Tooltip } from "@material-ui/core";
import { Link as RouterLink, useLocation, useHistory } from "react-router-dom";
import { Fragment, useContext, useEffect, useState } from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import AddIcon from '@material-ui/icons/Add';
import KeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { NotificationContext } from "../../Context/NotificationContext";
import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import UserService from "./../../Services/UserService";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import { UserContext } from "../../Context/UserContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import { FormattedMessage, useIntl } from "react-intl";
import CompanyService from "../../Services/CompanyService";
import SiteService from "../../Services/SiteService";
import { SpinnerContext } from "../../Context/SpinnerContext";
import SurveyService from "../../Services/SurveyService";
import DateFormat from "../DateFormat/DateFormat";


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(1.5),
        right: theme.spacing(1.5),
    },
    rowButton: {
        padding: '6px'
    },
}));

export default function Users() {
    const classes = useStyles();
    const { updateNotification } = useContext(NotificationContext);
    const { updateLoading } = useContext(SpinnerContext);
    const [isLoading, setIsLoading] = useState(true);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { user } = useContext(UserContext);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteUuid, setDeleteUuid] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(false);
    const intl = useIntl();
    let location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const companyId = searchParams.get('company');
    const urlParamSearch = searchParams.get('search') ? searchParams.get('search') : null;
    const history = useHistory();

    const [company, setCompany] = useState(null);
    const [sites, setSites] = useState({});
    const [sitesLoading, setSitesLoading] = useState(false);
    const [surveys, setSurveys] = useState({});
    const [surveysLoading, setSurveysLoading] = useState(false);

    const [tableData, setTableData] = useState({
        page: 0,
        count: 1,
        rowsPerPage: 20,
        sortOrder: {},
        data: [],
        searchText: '',
    });

    const hiddenOptions = {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
        searchable: false,
        print: false,
        download: false
    };

    const columns = [{
            name: 'id',
            options: hiddenOptions
        },
        {
            name: 'attributes.emailVerified',
            options: hiddenOptions
        },
        {
            label: intl.formatMessage({id: 'users.nameColumn', defaultMessage: 'Name'}),
            name: 'attributes.name',
        },
        {
            name: 'attributes.email',
            label: intl.formatMessage({id: 'users.emailColumn', defaultMessage: 'Email'}),
            options: {
                customBodyRender: (value, tableMeta) => (
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {tableMeta.rowData[1] ? <Fragment><span>{value}</span>&nbsp;<CheckCircleIcon style={{ color: '#4caf50'}} /></Fragment> : <Fragment><span>{value}</span></Fragment>}
                    </div>
                )
            }

        },
        {
            name: 'attributes.role',
            label: intl.formatMessage({id: 'users.roleColumn', defaultMessage: 'Role'}),
        },
        {
            name: 'attributes.enabled',
            label: intl.formatMessage({id: 'users.enabledColumn', defaultMessage: 'Enabled'}),
            options: {
                customBodyRender: (value) => {
                    return <span>{value ? 'Yes' : 'No'}</span>;
                }
            }
        },
        {
            name: 'relationships.company',
            label: intl.formatMessage({id: 'sites.companyColumn', defaultMessage: 'Company'}),
            options: user && user.role === 'admin' ? {
                display: location.search.startsWith('?company=') ? false : true,
                sort: false,
                customBodyRender: (value) => {
                    if (value) {
                        return <Link style={{ textDecoration: 'underline' }} component={RouterLink} to={'/companies/'+value.id}>{value.data.name}</Link>;
                    }
                    return null;
                }
            } : hiddenOptions
        },
        {
            name: 'attributes.twoFactorAuth',
            label: '2FA',
            options: {
                sort: false,
                customBodyRender: value => {
                    return <span>{value ? 'Enabled' : 'Disabled'}</span>
                }
            }
        },
        {
            name: 'attributes.lastLoginAt',
            label: intl.formatMessage({id: 'users.lastLoginColumn', defaultMessage: 'Last login'}),
            options: {
                customBodyRender: (value) => (<DateFormat date={value} />)
            }
        },
        {
            name: 'attributes.createdAt',
            label: intl.formatMessage({id: 'users.createdAtColumn', defaultMessage: 'Created'}),
            options: {
                display: -1 === location.search.indexOf('company='),
                customBodyRender: (value) => (<DateFormat date={value} />)
            }
        },
        {
            name: 'attributes.updatedAt',
            label: intl.formatMessage({id: 'users.updatedAtColumn', defaultMessage: 'Updated'}),
            options: {
                display: -1 === location.search.indexOf('company='),
                customBodyRender: (value) => (<DateFormat date={value} />)
            }
        },
        {
            name: 'relationships',
            label: intl.formatMessage({id: 'users.permissionsColumn', defaultMessage: 'Permissions'}),
            options: {
                display: companyId !== null,
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (relationships, tableMeta) => {

                    // Sites/surveys still being loaded
                    if (sitesLoading || surveysLoading) {
                        return <CircularProgress color="primary" size={20} />
                    }

                    if (0 === Object.keys(sites).length || 0 === Object.keys(surveys).length) {
                        return null;
                    }

                    if ('user-permissions' in relationships) {
                        // All surveys permission
                        if (1 === relationships['user-permissions'].length &&
                            relationships['user-permissions'][0].data.permission.survey.length === 1 &&
                            relationships['user-permissions'][0].data.permission.survey[0] === null
                        ) {
                            if (relationships['user-permissions'][0].data.permission.site.length === 1 &&
                                relationships['user-permissions'][0].data.permission.site[0] === null
                            ) {
                                // All sites
                                return (<div>
                                    <strong>All surveys:</strong>
                                    <ul style={{margin: 5}}><li>All sites</li></ul>
                                </div>);
                            }

                            return (<div><strong>All surveys:</strong><ul style={{margin: 5}}>
                                {relationships['user-permissions'][0].data.permission.site.map((siteId, i) => {
                                    return (<li key={i}>{siteId in sites ? sites[siteId].attributes.value : '(Not found)'}</li>);
                                })}
                            </ul></div>);
                        }

                        /**
                         * Generate something like this:
                         *
                         * Survey Foo 2022
                         * - Site A
                         * - Site C
                         *
                         * Survey Bar 2023
                         * - Site X
                         * - Site Y
                         */
                        const surveysWithSites = {};
                        for (const perm of relationships['user-permissions']) {
                            // All surveys access
                            if (perm.data.permission.survey.length === 1 && perm.data.permission.survey[0] === null) {
                                if (!('All surveys' in surveysWithSites)) {
                                    surveysWithSites['All surveys'] = [];
                                }

                                if (perm.data.permission.site.length === 1 && perm.data.permission.site[0] === null) {
                                    surveysWithSites['All surveys'] = ['All sites'];
                                } else {
                                    perm.data.permission.site.forEach(siteId => {
                                        surveysWithSites['All surveys'].push(siteId in sites ? sites[siteId].attributes.value : '(Not found)');
                                    });
                                }
                            } else if (perm.data.permission.site.length === 1 && perm.data.permission.site[0] === null) {
                                // All sites access
                                perm.data.permission.survey.forEach(survId => {
                                    surveysWithSites[survId in surveys ? surveys[survId].attributes.combinedHash : '(Not found)'] = ['All sites'];
                                });

                            } else {
                                // Normal 1-n surveys and 1-n sites access
                                perm.data.permission.survey.forEach(survId => {
                                    const surveyHash = survId in surveys ? surveys[survId].attributes.combinedHash : '(Not found)';
                                    if (!(surveyHash in surveysWithSites)) {
                                        surveysWithSites[surveyHash] = [];
                                    }
                                    perm.data.permission.site.forEach(siteId => {
                                        // Only add site to survey list if it actually is used in that survey
                                        const surveyUsedWithSite = (siteId in sites) ? sites[siteId].relationships.surveys.some(s => {
                                            return s.data.combinedHash === survId;
                                        }) : false;
                                        if (surveyUsedWithSite) {
                                            surveysWithSites[surveyHash].push(siteId in sites ? sites[siteId].attributes.value : '(Not found)');
                                        }
                                    });
                                });
                            }
                        }

                        return Object.keys(surveysWithSites).map((surveyHash, i) => {
                            const surveyTitle = (surveyHash in surveys) ? (surveys[surveyHash].relationships['survey-title'] ? surveys[surveyHash].relationships['survey-title'].data.title : surveys[surveyHash].attributes.title) : (surveyHash.toLowerCase() === 'all surveys' ? surveyHash : '(Not found)');
                            return (
                                <div key={i}>
                                    <strong>{surveyTitle}</strong>
                                    <ul>
                                        {surveysWithSites[surveyHash].map((siteTitle, j) => (
                                            <li key={j}>{siteTitle}</li>
                                        ))}
                                    </ul>
                                </div>
                            );
                        });
                    } else {
                        return null;
                    }
                }
            }
        },
        {
            name: '',
            label: '',
            options: {
                filter: false,
                sort: false,
                searchable: false,
                customBodyRender: (value, tableMeta) => {
                    if (-1 !== ['premium', 'premium-admin', 'ssafe', 'brcgs-free', 'brcgs-admin', 'brcgs', 'partner'].indexOf(tableMeta.rowData[4])) {
                        return (
                            <Fragment>
                                <Tooltip title={(intl.formatMessage({id: 'generic.edit', defaultMessage: 'Edit'}))}>
                                    <IconButton className={classes.rowButton} component={RouterLink} to={'/users/'+tableMeta.rowData[0]}>
                                        <EditIcon fontSize="small" style={{ color: '#f57c00'}} />
                                    </IconButton>
                                </Tooltip>
                                { user && user.role === 'admin' ?
                                    <Tooltip title={(intl.formatMessage({id: 'users.userPermission', defaultMessage: 'User permissions'}))}>
                                        <IconButton className={classes.rowButton} color="primary" component={RouterLink} to={'/users/'+tableMeta.rowData[0]+'/permissions'}>
                                            <KeyIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                : null }
                                { user && user.role === 'admin'  ?
                                    <Tooltip title={(intl.formatMessage({id: 'generic.delete', defaultMessage: 'Delete'}))}>
                                        <IconButton className={classes.rowButton} color="secondary" onClick={() => {setDeleteUuid(tableMeta.rowData[0]); setDeleteDialogOpen(true)}}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                : null }
                            </Fragment>
                        );
                    }
                    return null;
                },
                setCellProps: () => ({
                    align: 'right'
                }),
            }
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: isLoading ? '...' : intl.formatMessage({id: 'generic.noData', defaultMessage: 'No data...'}),
            }
        },
        tableBodyHeight: 'auto',
        searchPlaceholder: intl.formatMessage({id: 'users.searchPlaceholder', defaultMessage: 'User name/email/role/company name...'}),
        enableNestedDataAccess: '.',
        selectableRows: 'none',
        searchText: urlParamSearch,
        customSearchRender: debounceSearchRender(500),
        filter: false,
        searchOpen: false,
        download: false,
        print: false,
        viewColumns: false,
        elevation: 0,
        serverSide: true,
        count: tableData.count,
        rowsPerPage: tableData.rowsPerPage,
        rowsPerPageOptions: [20, 50, 100],
        onChangeRowsPerPage: (newPageSize) => setTableData(prevTableData => ({...prevTableData, rowsPerPage: newPageSize})),
        sortOrder: tableData.sortOrder,
        onTableChange: (action, tableState) => {
            const sortCol = tableState.sortOrder.name ? (tableState.sortOrder.name.replace('attributes.', '')) : 'name';
            const sortDir = tableState.sortOrder.direction ? (tableState.sortOrder.direction === 'asc' ? '' : '-') : '';

            switch (action) {
                case 'changePage':
                    fetchUsers(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'sort':
                    fetchUsers(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                case 'search':
                    fetchUsers(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText)
                    break;

                case 'propsUpdate':
                    if (forceRefresh) {
                        setForceRefresh(false, fetchUsers(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText));
                    }
                    break;

                case 'changeRowsPerPage':
                    fetchUsers(tableState.page, tableState.rowsPerPage, sortDir+sortCol, tableState.searchText);
                    break;

                default:
                    console.log(action, 'action not handled.');
            }
        },
    };

    const fetchUsers = (page, pageSize, sort, search = null) => {
        search = search === '' ? null : search;
        const queryParams = [];
        if (companyId) {
            queryParams.push('company='+companyId);
        }
        if (search !== null) {
            queryParams.push('search='+encodeURIComponent(search));
        }
        history.push({search: queryParams.join('&')});
        console.log('FETCH#', page, pageSize, search);
        setIsLoading(true);
        const filter = {};
        if (companyId) {
            filter['company'] = companyId;
        }
        UserService.users(page*pageSize, pageSize, sort, search, filter)
        .then(function (response) {
            setTableData({
                data: response.data.data,
                count: response.data.meta.count,
                rowsPerPage: pageSize,
                searchText: search,
                page: page
            });
        }).catch(function (error) {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        }).then(function() {
            setIsLoading(false);
        });
    };

    useEffect(() => {
        setIsLoading(true);

        if (companyId) {
            // Fetch company info
            CompanyService.company(companyId).then(function (response) {
                setCompany(response.data.data);
            }).catch(function (error) {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            });

            // Fetch sites
            setSitesLoading(true);
            SiteService.allSites('name', null, {company: companyId}).then(response => {
                // Make it easy to find sites based on id
                const sitesToSet = {};
                response.forEach(site => {
                    sitesToSet[site.id] = site;
                });
                setSites(sitesToSet);
            }).catch(error => {
                console.log(error);
            }).then(function() {
                setSitesLoading(false);
            });

            setSurveysLoading(true);
            SurveyService.allSurveysByHash('title', null, {company: companyId}).then(response => {
                // Make it easy to find surveys based on combinedHash
                const surveysToSet = {};
                response.forEach(survey => {
                    surveysToSet[survey.attributes.combinedHash] = survey;
                });
                setSurveys(surveysToSet);
            }).catch(error => {
                console.log(error);
            }).then(function() {
                setSurveysLoading(false);
            });
        } else {
            setCompany(null);
        }

        // Fetch users if this is the initial load
        fetchUsers(0, tableData.rowsPerPage, null, urlParamSearch);
        updateTitle(null);
        // eslint-disable-next-line
    }, [companyId]);

    const handleDelete = () => {
        // To avoid multiple submits
        if (!deleteDialogOpen) {
            return;
        }
        setDeleteDialogOpen(false);
        updateLoading(true);

        UserService.delete(deleteUuid)
        .then(function (response) {
            setDeleteUuid(null);
            setForceRefresh(true);
        }).catch(function (error) {
            if (error.response && error.response.status) {
                if (error.response.data.error.detail) {
                    updateNotification(true, error.response.data.error.detail, 'error');
                } else {
                    updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
                }
            } else {
                updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            }
            console.error(error);
        }).then(function () {
            updateLoading(false);
        });
    }

    return(
        <Fragment>
            <Grid container spacing={0} justifyContent="center">
                <Grid item sm={12} style={{paddingBottom: '60px'}}>
                    <LocalBackdrop open={isLoading || surveysLoading || sitesLoading} transitionDuration={600}>
                        <CircularProgress color="secondary" size={40} />
                    </LocalBackdrop>
                    <MUIDataTable
                        title={company ? 'Users for: '+company.attributes.name : ''}
                        data={tableData.data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => {setDeleteDialogOpen(false)}}
            >
                <DialogTitle><FormattedMessage id="generic.delete" defaultMessage="Delete" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="users.confirmDelete" defaultMessage="This will delete the user and all associated permissions, continue?" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => {setDeleteDialogOpen(false)}} color="primary" autoFocus>
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="contained" onClick={handleDelete} color="secondary">
                        <FormattedMessage id="generic.confirm" defaultMessage="Confirm" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Fab className={classes.fab} component={RouterLink} to="/users/new" color="secondary">
                <AddIcon />
            </Fab>
        </Fragment>
    );
}
