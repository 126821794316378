import axios from 'axios';
import BaseService from './BaseService';

class SurveyService extends BaseService {

    rogueResponses(combinedHash) {
        return axios.get(this.apiUrl('/surveys/hash/'+combinedHash+'/rogue-responses'), {
            headers: this.getAuthHeader(),
        });
    }

    rogueResponsesElementRole(combinedHash, element, role) {
        return axios.get(this.apiUrl('/surveys/hash/'+combinedHash+'/rogue-responses-element-role'), {
            params: {
                element: element,
                role: role,
            },
            headers: this.getAuthHeader()
        });
    }

    stuck() {
        return axios.get(this.apiUrl('/surveys/stuck'), {
            headers: this.getAuthHeader(),
        });
    }

    copyAndMerge(surveys, targetCompany, targetTitle) {
        return axios.post(this.apiUrl('/surveys/copy-merge'), {
            data: {
                company: targetCompany,
                surveys: surveys,
                title: targetTitle
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    copy(survey, targetCompany, targetTitle) {
        return axios.post(this.apiUrl('/surveys/copy-merge'), {
            data: {
                company: targetCompany,
                surveys: [survey],
                title: targetTitle
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    surveyCloseCheck(combinedHash, token) {
        const params = {
            token: token
        };

        return axios.get(this.apiUrl('/surveys/closecheck/'+combinedHash), {
            params: params
        });
    }

    surveyClose(combinedHash, token) {
        return axios.post(this.apiUrl('/surveys/close/'+combinedHash), {
            token: token
        });
    }

    surveyCloseAdmin(combinedHash, skipWorkflow = false) {
        return axios.post(this.apiUrl('/surveys/adminclose/'+combinedHash), {
            skipWorkflow: skipWorkflow
        },{
            headers: this.getAuthHeader()
        });
    }

    surveyRevisionsByHash(hash, offset = 0, limit = 10) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
        };

        return axios.get(this.apiUrl('/surveys/hash/'+hash+'/revisions'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    surveysByHash(offset = 0, limit = 50, sort = null, search = null, filter = null) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
            'search': search,
            'sort': sort,
        };

        if (filter && 0 !== Object.keys(filter).length) {
            Object.keys(filter).forEach(key => {
                params['filter['+key+']'] = filter[key];
            });
        }
        return axios.get(this.apiUrl('/surveys/hash'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    async allSurveysByHash(sort = null, search = null, filter = null, offset = 0) {
        const response = await this.surveysByHash(offset, 50, sort, search, filter);
        const data = response.data.data;
        if (response.data.links.next) {
            return data.concat(await this.allSurveysByHash(sort, search, filter, offset+50));
        } else {
            return data;
        }
    }

    surveyByHash(hash, cancelToken = null, include = ['demographic']) {
        const config = {
            params: {
                'include': include.join('|')
            },
            headers: this.getAuthHeader()
        };

        if (cancelToken) {
            config['cancelToken'] = cancelToken;
        }

        return axios.get(this.apiUrl('/surveys/hash/'+hash), config);
    }

    surveyStaticReport(uuid, type) {
        return axios.get(this.apiUrl('/surveyreport/'+type+'/'+uuid+'/'), {
            headers: this.getAuthHeader(),
            responseType: 'blob',
        });
    }

    dataExportReport(uuid, filters, type) {
        return axios.post(this.apiUrl('/surveyreport/data-export/'+uuid), {
            data: {
                type: 'data-export',
                attributes: {
                    filters: filters,
                    type: type
                },
            },
        },{
            headers: this.getAuthHeader()
        });
    }

    surveyReport(uuid, type, demographicsFilter = null, typesFilter = null, cancelToken = null, params = null) {
        const reqParams = {};
        if (demographicsFilter && 0 !== Object.keys(demographicsFilter).length) {
            Object.keys(demographicsFilter).forEach((demographicKey) => {
                reqParams['demographics['+demographicKey+']'] = demographicsFilter[demographicKey].join('|')
            });
        }

        if (params && 0 !== params.length) {
            Object.keys(params).forEach((key) => {
                reqParams['params['+key+']'] = params[key];
            });
        }

        /*
         * Some older surveys have "foodsafety-quality" culture type questions that need to be included if
         * culture type filters include foodsafety and/or quality. For newer surveys which does not have
         * "foodsafety-quality" questions, this will just match no questions and will not affect the report
         */
        let typesFilterHack = null;
        if (typesFilter !== null && (-1 !== typesFilter.indexOf('foodsafety') || -1 !== typesFilter.indexOf('quality'))) {
            typesFilterHack = [...typesFilter, 'foodsafety-quality'];
        } else if (typesFilter !== null) {
            typesFilterHack = [...typesFilter];
        }

        if (typesFilterHack && 0 !== typesFilterHack.length) {
            reqParams['types'] = typesFilterHack.join('|');
        }

        const config = {
            params: reqParams,
            headers: this.getAuthHeader()
        };

        if (cancelToken) {
            config['cancelToken'] = cancelToken;
        }

        // Fetch all the reports needed for executive summary
        if (type === 'executive-summary') {
            const requests = [];
            const reportTypes = [
                'basic-data',
                'demographic-comparison',
                'elements',
                'element-hotspots',
                'element-hotspots-lowest',
                'priorities',
                'priority-hotspots',
                'priority-conflict-hotspots',
                'positives',
                'barriers',
                'risks',
                'benchmark-industry',
                'demographics'
            ];

            reportTypes.forEach(t => {
                if (-1 !== ['element-hotspots', 'priority-hotspots', 'priority-conflict-hotspots'].indexOf(t)) {
                    // Include all demographics on hotspot reports
                    config.params['params[demographics]'] = '*';
                } else if (t === 'demographics') {
                    config.params['params[demographics]'] = 'site';
                } else {
                    delete config.params['params[demographics]'];
                }

                requests.push(axios.get(this.apiUrl('/surveyreport/'+uuid+'/'+t), config));
            });


            return Promise.all(requests).then(response => {
                // Construct a single report from multiple requests
                const combinedResponse = {
                    data: {},
                    generatedAt: '',
                    type: type
                };

                response.forEach(r => {
                    combinedResponse.data[r.data.type] = r.data.data;
                    if (r.data.generatedAt > combinedResponse.generatedAt) {
                        combinedResponse.generatedAt = r.data.generatedAt;
                    }
                });

                return Promise.resolve({
                    data: combinedResponse
                });
            }).catch(error => {
                return Promise.reject(error);
            });
        } else {
            return axios.get(this.apiUrl('/surveyreport/'+uuid+'/'+type), config);
        }
    }

    deleteSurveyCorrelation(uuid) {
        return axios.delete(this.apiUrl('/survey-correlation/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    patchSurveyCorrelation(uuid, combinedHash, title, scale, scores) {
        return axios.patch(this.apiUrl('/survey-correlation/'+uuid), {
            data: {
                type: 'survey-correlation',
                attributes: {
                    title: title,
                    scale: scale,
                    siteScores: scores,
                    surveyCombinedHash: combinedHash,
                },
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    createSurveyCorrelation(combinedHash, title, scale, scores) {
        return axios.post(this.apiUrl('/survey-correlation'), {
            data: {
                type: 'survey-correlation',
                attributes: {
                    title: title,
                    scale: scale,
                    siteScores: scores,
                    surveyCombinedHash: combinedHash,
                },
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    surveyProgressReport(combinedHash, userIds, demographicTypes) {
        return axios.post(this.apiUrl('/surveys/'+combinedHash+'/progress-report'), {
            data: {
                users: userIds,
                demographicTypes: demographicTypes
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    patchSurveyProgressReportSchedyle(uuid, combinedHash, userIds, demographics, days) {
        const users = [];
        userIds.forEach(userId => {
            users.push({
                type: 'user',
                id: userId
            });
        });

        return axios.patch(this.apiUrl('/progress-report-schedules/'+uuid), {
            data: {
                type: 'progress-report-schedule',
                attributes: {
                    demographics: demographics,
                    surveyCombinedHash: combinedHash,
                    days: days
                },
                relationships: {
                    users: users,
                },
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    createSurveyProgressReportSchedule(combinedHash, userIds, demographics, days) {
        const users = [];
        userIds.forEach(userId => {
            users.push({
                type: 'user',
                id: userId
            });
        });

        return axios.post(this.apiUrl('/progress-report-schedules'), {
            data: {
                type: 'progress-report-schedule',
                attributes: {
                    demographics: demographics,
                    surveyCombinedHash: combinedHash,
                    days: days
                },
                relationships: {
                    users: users,
                },
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    deleteSurveyProgressReportSchedule(uuid) {
        return axios.delete(this.apiUrl('/progress-report-schedules/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    surveyProgressReportSchedules(hash, offset = 0, limit = 50) {
        const params = {
            'page[offset]': offset,
            'page[limit]': limit,
        };

        if (hash) {
            params['filter[surveyCombinedHash]'] = hash;
        }

        return axios.get(this.apiUrl('/progress-report-schedules'), {
            params: params,
            headers: this.getAuthHeader()
        });
    }

    relaunch(uuid, token) {
        return axios.post(this.apiUrl('/surveys/'+uuid+'/relaunch'), {
            data: {
                token: token
            }
        },{
            headers: this.getAuthHeader()
        });
    }

    survey(uuid) {
        return axios.get(this.apiUrl('/surveys/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    deleteByCombinedHash(combinedHash, allRevisions = false) {
        return axios.delete(this.apiUrl('/surveys/hash/'+combinedHash+'?allRevisions='+(allRevisions ? 'true' : 'false')), {
            headers: this.getAuthHeader()
        });
    }

    delete(uuid) {
        return axios.delete(this.apiUrl('/surveys/'+uuid), {
            headers: this.getAuthHeader()
        });
    }

    register(type, data) {
        return axios.post(this.apiUrl('/surveys/'+type), {
            data: data
        },{
            headers: this.getAuthHeader()
        });
    }
}

export default new SurveyService();
