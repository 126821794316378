import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Tab, Tabs } from "@material-ui/core";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import SurveyService from "../../../Services/SurveyService";
import { NotificationContext } from "../../../Context/NotificationContext";
import JobService from "../../../Services/JobService";

const useStyles = makeStyles(theme => ({
    topIndicator: {
        backgroundColor: theme.palette.secondary.main,
    },
    topTabs: {
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    },
}));

export default function DataExport(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [jobResult, setJobResult] = useState(null);
    const timer = useRef(null);
    const { updateNotification } = useContext(NotificationContext);

    useEffect(() => {
        return () => clearTimeout(timer.current)
    }, []);

    if (!props.surveyData || !props.surveyData.id) {
        return null;
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const tabList = [
        {
            description: intl.formatMessage({id: 'dataExport.basicDataDesc', defaultMessage: 'Basic data report with total, category and dimension scores for sites along with total, category and dimension scores for each individual site.'}),
            filename: 'basic-data-export-%s.csv',
            type: 'export-basic-data'
        },
        {
            description: intl.formatMessage({id: 'dataExport.basicDataExtendedDesc', defaultMessage: 'Basic data report with total, category and dimension scores for sites along with total, category and dimension scores for each individual site and role combination.'}),
            filename: 'basic-data-export-extended-%s.csv',
            type: 'export-basic-data-extended'
        },
        {
            description: intl.formatMessage({id: 'dataExport.elementsDesc', defaultMessage: 'Elements report with element scores for sites along with element scores for each individual site.'}),
            filename: 'elements-export-%s.csv',
            type: 'export-elements'
        },
        {
            description: intl.formatMessage({id: 'dataExport.elementsExtendedDesc', defaultMessage: 'Elements report with element scores for sites along with element scores for each individual site and role combination.'}),
            filename: 'elements-export-extended-%s.csv',
            type: 'export-elements-extended'
        }
    ];

    const downloadReport = (data, filename) => {
        const blob = new Blob([data], { type: 'text/csv' });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        const date = new Date();
        a.download = -1 !== filename.indexOf('%s') ? (filename.replace('%s', date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+'_'+date.getHours()+'-'+date.getMinutes())) : filename;
        a.click();
        setDialogOpen(false);
        setJobResult(null);
    };

    const fetchJobResult = (jobUuid) => {
        // Wait for completion, download once 100% done
        JobService.jobResult(jobUuid)
        .then(response => {
            setJobResult(response.data.data);
            if (response.data.data.attributes.error !== null) {
                updateNotification(true, response.data.data.attributes.error, 'error');
                setDialogOpen(false);
                setJobResult(null);
            } else if (response.data.data.attributes.percentDone !== 100) {
                timer.current = setTimeout(() => {fetchJobResult(jobUuid)}, 5000);
            } else {
                // Trigger download dialog (some browsers auto download to a default folder)
                downloadReport(response.data.data.attributes.data, tabList[activeTab].filename);
            }
        }).catch(error => {
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
        });
    };

    const requestDownload = (type) => () => {
        setDialogOpen(true);
        // Queue job
        SurveyService.dataExportReport(props.surveyData.id, props.filter, type)
        .then(response => {
            fetchJobResult(response.data.queued);
        }).catch(error => {
            console.log(error);
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            setDialogOpen(false);
        });
    };

    const downloadDone = jobResult && jobResult.attributes.percentDone === 100 ? true : false;

    return (
        <Fragment>
            <Tabs classes={{ root: classes.topTabs, indicator: classes.topIndicator }} value={activeTab} onChange={handleTabChange} variant="fullWidth">
                <Tab label={intl.formatMessage({id: 'reportmenu.basicData', defaultMessage: 'Basic Data'})} />
                <Tab label={intl.formatMessage({id: 'dataExport.basicDataExtended', defaultMessage: 'Basic Data Extended'})} />
                <Tab label={intl.formatMessage({id: 'reportmenu.elements', defaultMessage: 'Elements'})} />
                <Tab label={intl.formatMessage({id: 'dataExport.elementsExtended', defaultMessage: 'Elements Extended'})} />
            </Tabs>
            {tabList.map((currentTab, tabIndex) => {
                if (activeTab !== tabIndex) {
                    return null;
                }

                return (<Box py={2} key={tabIndex}>
                    <p>
                        {currentTab.description}
                    </p>
                    <Button size="small" color="secondary" variant="outlined" onClick={requestDownload(currentTab.type)}>
                        <FormattedMessage id="generic.generate" defaultMessage="Generate" />
                    </Button>
                </Box>);
            })}
            <Dialog
                open={dialogOpen}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        setDialogOpen(false);
                    }
                }}
                style={{
                    zIndex: 1401
                }}
            >
                <DialogTitle>
                    <FormattedMessage id="dataExport.generatingReport" defaultMessage="Generating report" />
                </DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <CircularProgress color="secondary" variant={downloadDone ? 'determinate' : 'indeterminate'} value={downloadDone ? 100 : null}/>
                    </div>
                    <DialogContentText style={{paddingTop: 15}}>
                        {downloadDone
                            ? <FormattedMessage id="dataExport.downloadDone" defaultMessage="Done - download below." />
                            : <FormattedMessage id="generic.thisMayTakeAWhile" defaultMessage="This may take a while..." />
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" size="small" onClick={() => {clearTimeout(timer.current); setDialogOpen(false); setJobResult(null);}} color="primary">
                        <FormattedMessage id="generic.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button variant="outlined" size="small" color="secondary" disabled={!downloadDone} onClick={() => downloadReport(downloadDone ? jobResult.attributes.data : '', tabList[activeTab].filename)}>
                        <FormattedMessage id="generic.download" defaultMessage="Download"/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}