import { Table, TableBody, TableHead, TableCell, TableContainer, TableRow, TableSortLabel, FormGroup, FormControlLabel, Checkbox, Box, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { CATEGORY_COLORS } from '../../../Constants/CategoryColors';
import { BRCGS_CATEGORY_COLORS } from "../../../Constants/BrcgsCategoryColors";
import FilterListIcon from '@material-ui/icons/FilterList';
import CategoryLabel from "../../Translation/CategoryLabel";
import ElementLabel from "../../Translation/ElementLabel";
import CompareArrow from "./Common/CompareArrow";
import {Decimal} from 'decimal.js';
import { FormattedMessage } from "react-intl";


const useStyles = makeStyles(() => ({
    label: {
        marginLeft: 0
    },
}));

export default function ElementsComparison(props) {
    const classes = useStyles();
    const [sortBy, setSortBy] = useState('score');
    const [sortOrder, setSortOrder] = useState('desc');
    const [categories, setCategories] = useState({
        people: true,
        process: true,
        purpose: true,
        proactivity: true
    });

    if (!props.reportData.data || !props.reportData.data.current || !props.surveyData || 0 === Object.keys(props.surveyData.attributes).length) {
        return null;
    }

    // Determine colors for report based on type
    const categoryColors = props.surveyData.attributes.type === 'brcgs' ? BRCGS_CATEGORY_COLORS : CATEGORY_COLORS;

    const toggleSort = (column) => {
        // Toggle direction on already sorted by column
        if (sortBy === column) {
            setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
        } else {
            // Sort by new column, descending
            setSortBy(column);
            setSortOrder('desc');
        }
    };

    const toggleCategory = (category) => {
        // Don't allow the last category to be deselected
        const enabledCategories = Object.keys(categories).filter((current) => {
            return categories[current];
        });
        if (1 === enabledCategories.length && categories[category]) {
            return;
        }
        setCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const sortedData = () => {
        const dataCopy = JSON.parse(JSON.stringify(props.reportData.data.current ? props.reportData.data.current : []));

        // Find previous score for each element
        dataCopy.forEach((element, index) => {
            dataCopy[index]['prevScore'] = null;
            dataCopy[index]['change'] = null;
            if (props.reportData.data.previous) {
                let elementMap = [];
                // If previous score was not found, see if it exists in element mappings
                if (props.reportData.data['element-map'] && props.reportData.data['element-map'][element.shortName]) {
                    elementMap = props.reportData.data['element-map'][element.shortName];
                }

                props.reportData.data.previous.forEach((prevElement, prevIndex) => {
                    if (element.category === prevElement.category &&
                        element.dimension === prevElement.dimension &&
                        element.shortName === prevElement.shortName
                    ) {
                        dataCopy[index]['prevScore'] = prevElement.score;
                        dataCopy[index]['change'] = (Decimal(element.score).minus(Decimal(prevElement.score))).toFixed(1);
                    } else if (elementMap.length) {
                        elementMap.forEach((e) => {
                            if (prevElement.shortName === e &&
                                element.category === prevElement.category &&
                                element.dimension === prevElement.dimension
                            ) {
                                dataCopy[index]['prevScore'] = prevElement.score;
                                dataCopy[index]['change'] = (Decimal(element.score).minus(Decimal(prevElement.score))).toFixed(1);
                            }
                        });
                    }
                });
            }
        })
        dataCopy.sort((a, b) => {
            return (a[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(a[sortBy])) > (b[sortBy] === null ? Number.MIN_SAFE_INTEGER : parseFloat(b[sortBy])) ? 1 : -1
        });
        if ('desc' === sortOrder) {
            dataCopy.reverse();
        }

        return dataCopy;
    }

    return (
        <Box pt={2}>
            <div ref={props.setScreenshotRef}>
                <FormGroup row style={{justifyContent: 'center'}}>
                    <FormControlLabel
                        className={classes.label}
                        label={<CategoryLabel category="people" />}
                        style={{backgroundColor: categoryColors['people'].light, borderLeft: '5px solid '+categoryColors['people'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.people}
                                onChange={() => toggleCategory('people')}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<CategoryLabel category="process" />}
                        style={{backgroundColor: categoryColors['process'].light, borderLeft: '5px solid '+categoryColors['process'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.process}
                                onChange={() => toggleCategory('process')}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<CategoryLabel category="purpose" />}
                        style={{backgroundColor: categoryColors['purpose'].light, borderLeft: '5px solid '+categoryColors['purpose'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.purpose}
                                onChange={() => toggleCategory('purpose')}
                            />
                        }
                    />
                    <FormControlLabel
                        label={<CategoryLabel category="proactivity" />}
                        style={{backgroundColor: categoryColors['proactivity'].light, borderLeft: '5px solid '+categoryColors['proactivity'].dark, paddingRight: '10px'}}
                        control={
                            <Checkbox
                                color="primary"
                                style={{ padding: '5px' }}
                                checked={categories.proactivity}
                                onChange={() => toggleCategory('proactivity')}
                            />
                        }
                    />
                </FormGroup>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="generic.category" defaultMessage={"Category"} />
                                </TableCell>
                                <TableCell>
                                    <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel
                                        IconComponent={FilterListIcon}
                                        active={sortBy === 'score'}
                                        direction={(sortBy === 'score' ? sortOrder : 'desc')}
                                        onClick={() => {toggleSort('score')}}
                                    >
                                        <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"} />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" style={{}}>
                                    <TableSortLabel
                                        IconComponent={FilterListIcon}
                                        active={sortBy === 'prevScore'}
                                        direction={(sortBy === 'prevScore' ? sortOrder : 'desc')}
                                        onClick={() => {toggleSort('prevScore')}}
                                    >
                                        <FormattedMessage id="generic.comparisonPercent" defaultMessage={"Comparison (%)"} />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center">
                                    <TableSortLabel
                                        IconComponent={FilterListIcon}
                                        active={sortBy === 'change'}
                                        direction={(sortBy === 'change' ? sortOrder : 'desc')}
                                        onClick={() => {toggleSort('change')}}
                                    >
                                        <FormattedMessage id="generic.difference" defaultMessage={"Difference"} />
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData().map((entity, index) => {
                                if (!categories[entity.category]) {
                                    return null;
                                }

                                return (
                                    <TableRow key={index} style={{backgroundColor: categoryColors[entity.category].light, borderBottom: '10px solid white'}}>
                                        <TableCell style={{borderLeft: '5px solid '+categoryColors[entity.category].dark}}>
                                            <CategoryLabel category={entity.category} />
                                        </TableCell>
                                        <TableCell>
                                            {entity.shortName ? <ElementLabel element={entity.shortName} /> : entity.title}
                                        </TableCell>
                                        <TableCell align="center">
                                            <span title={entity.score} style={{fontWeight: 'bold'}}>{Decimal(entity.score).toFixed(1)}</span>
                                        </TableCell>
                                        <TableCell align="center">
                                            <span>{(entity.prevScore ? Decimal(entity.prevScore).toFixed(1) : '-')}</span>
                                        </TableCell>
                                        <TableCell align="center">
                                            {(entity.change ?
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}>
                                                    <span>{entity.change}</span>
                                                    <CompareArrow change={entity.change} />
                                                </div>
                                            : '-')}

                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Box>
    );
}
