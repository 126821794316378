import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import Reports from "./Reports";


export default function CustomReports() {
    const [activeTab, setActiveTab] = useState(0);

    const tabContent = (tab) => {
        if (0 === tab) {
            return <Reports type="brcgs-basic" />
        } else if (1 === tab) {
            return <Reports type="ssafe" />
        } else if (2 === tab) {
            return <Reports type="sites" />
        } else if (3 === tab) {
            return <Reports type="users" />
        } else if (4 === tab) {
            return <Reports type="companies" />
        }
    }

    return (
        <Grid container spacing={0} justifyContent="center">
            <Grid item sm={12} md={8} lg={6}>
                <Tabs
                    value={activeTab}
                    onChange={(event, newValue) => setActiveTab(newValue)}
                    variant="standard"
                >
                    <Tab label="BRCGS Basic" />
                    <Tab label="SSAFE" />
                    <Tab label="Sites" />
                    <Tab label="Users" />
                    <Tab label="Companies" />
                </Tabs>
                <Box py={2}>
                    {tabContent(activeTab)}
                </Box>
            </Grid>
        </Grid>
    );
}