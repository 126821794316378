import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { createElement } from 'react';

export default function AlignmentIcon(props) {

    const iconStyle = {};
    let iconType = ArrowForwardIcon;
    if (props.large) {
        iconStyle['width'] = '72px';
        iconStyle['height'] = '72px';
    }

    if (props.standardDeviation >= 30) {
        iconStyle['color'] = 'rgb(210,50,0)';
        iconStyle['transform'] = 'rotate(90deg)';
    } else if (props.standardDeviation >= 25) {
        iconStyle['color'] = 'rgb(225,105,0)';
        iconStyle['transform'] = 'rotate(60deg)';
    } else if (props.standardDeviation >= 20) {
        iconStyle['color'] = 'rgb(240,160,0)';
        iconStyle['transform'] = 'rotate(30deg)';
    } else if (props.standardDeviation >= 15) {
        iconStyle['color'] = 'rgb(255, 210, 0)';
        iconStyle['transform'] = 'rotate(0deg)';
    } else if (props.standardDeviation >= 10) {
        iconStyle['color'] = 'rgb(170,180,20)';
        iconStyle['transform'] = 'rotate(-30deg)';
    } else if (props.standardDeviation >= 5) {
        iconStyle['color'] = 'rgb(85,150,40)';
        iconStyle['transform'] = 'rotate(-60deg)';
    } else {
        iconStyle['color'] =  'rgb(0,120,60)';
        iconStyle['transform'] = 'rotate(-90deg)';
    }

    return createElement(iconType, {style: iconStyle});
}
