export const REPORT_TYPES = [
    // Individual reports
    'barriers',
    'barriers-comparison',
    'basic-data-comparison',
    'benchmark-barriers-company',
    'benchmark-barriers-country',
    'benchmark-barriers-industry',
    'benchmark-barriers-region',
    'benchmark-barriers-sector',
    'benchmark-company',
    'benchmark-country',
    'benchmark-elements-company',
    'benchmark-elements-country',
    'benchmark-elements-industry',
    'benchmark-elements-region',
    'benchmark-elements-sector',
    'benchmark-industry',
    'benchmark-positives-company',
    'benchmark-positives-country',
    'benchmark-positives-industry',
    'benchmark-positives-region',
    'benchmark-positives-sector',
    'benchmark-quality-company',
    'benchmark-quality-country',
    'benchmark-quality-industry',
    'benchmark-quality-region',
    'benchmark-quality-sector',
    'benchmark-region',
    'benchmark-sector',
    'culture-type',
    'demographic-comparison',
    'demographics',
    'element-hotspots',
    'element-hotspots-lowest',
    'element-ranges',
    'elements',
    'elements-comparison',
    'environment',
    'environment-comparison',
    'executive-summary',
    'positives',
    'positives-comparison',
    'priorities',
    'priorities-comparison',
    'priority-conflict-hotspots',
    'priority-hotspots',
    'progression',
    'qualitative-comments',
    'quality',
    'quality-comparison',
    'response-distribution',
    'risks',
    'risks-comparison',

    // Groups (covers all the reports above with some magic in the backend)
    'premium',

    // All export-... reports
    'data-export',

    // Features
    'filter-demographic'
];