import { Grid, makeStyles, Typography, CircularProgress, Box, Checkbox, Hidden, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Button, DialogContentText, Tooltip, Fab, IconButton, MenuItem, Select, FormControl, FormHelperText } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import Alert from '@material-ui/lab/Alert';
import { NotificationContext } from "../../Context/NotificationContext";
import SurveyService from "./../../Services/SurveyService";
import SiteService from "./../../Services/SiteService";
import { useParams, useLocation } from "react-router-dom";
import BasicData from "./Reports/BasicData";
import BrcgsBasicData from "./Reports/BrcgsBasicData";
import Demographics from "./Reports/Demographics";
import Elements from "./Reports/Elements";
import ReportMenu from "./Menu/ReportMenu";
import FilterMenu from "./Menu/FilterMenu";
import BrcgsFreeBasicData from "./Reports/BrcgsFreeBasicData";
import BrcgsFreeElements from "./Reports/BrcgsFreeElements";
import SsafeBasicData from "./Reports/SsafeBasicData";
import SsafeElements from "./Reports/SsafeElements";
import BrcgsElements from "./Reports/BrcgsElements";
import { AppBarTitleContext } from "../../Context/AppBarTitleContext";
import LocalBackdrop from "../LocalBackdrop/LocalBackdrop";
import Definitions from "./Reports/Definitions";
import { FormattedMessage, useIntl } from "react-intl";
import { FilterContext } from "../../Context/FilterContext";
import { UserContext } from "../../Context/UserContext";
import ResponseDistribution from "./Reports/ResponseDistribution";
import Risks from "./Reports/Risks";
import QualitativeComments from "./Reports/QualitativeComments";
import DemographicLabel from "../Translation/DemographicLabel";
import Quality from "./Reports/Quality";
import QualityComparison from "./Reports/QualityComparison";
import Positives from "./Reports/Positives";
import Barriers from "./Reports/Barriers";
import Environment from "./Reports/Environment";
import Priorities from "./Reports/Priorities";
import ElementHotspots from "./Reports/ElementHotspots";
import PrioritiesComparison from "./Reports/PrioritiesComparison";
import Ideas from "./Reports/Ideas";
import DemographicComparison from "./Reports/DemographicComparison";
import axios from "axios";
import ElementsComparison from "./Reports/ElementsComparison";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import BasicDataComparison from "./Reports/BasicDataComparison";
import BenchmarkCompany from "./Reports/BenchmarkCompany";
import BenchmarkCountry from "./Reports/BenchmarkCountry";
import { industryTypeTranslated } from "../Translation/IndustryType";
import { COUNTRIES } from "../../Constants/Countries";
import { REGIONS } from "../../Constants/Regions";
import { countryTranslated } from "../Translation/Country";
import BenchmarkIndustry from "./Reports/BenchmarkIndustry";
import BenchmarkSector from "./Reports/BenchmarkSector";
import BenchmarkRegion from "./Reports/BenchmarkRegion";
import PriorityHotspots from "./Reports/PriorityHotspots";
import PriorityConflictHotspots from "./Reports/PriorityConflictHotspots";
import BenchmarkElementsIndustry from "./Reports/BenchmarkElementsIndustry";
import BenchmarkElementsCompany from "./Reports/BenchmarkElementsCompany";
import BenchmarkSsafe from "./Reports/BenchmarkSsafe";
import BenchmarkElementsSsafe from "./Reports/BenchmarkElementsSsafe";
import BenchmarkElementsCountry from "./Reports/BenchmarkElementsCountry";
import BenchmarkQualityCompany from "./Reports/BenchmarkQualityCompany";
import BenchmarkQualityCountry from "./Reports/BenchmarkQualityCountry";
import BenchmarkQualityRegion from "./Reports/BenchmarkQualityRegion";
import BenchmarkQualityIndustry from "./Reports/BenchmarkQualityIndustry";
import BenchmarkQualitySector from "./Reports/BenchmarkQualitySector";
import BenchmarkPositivesCompany from "./Reports/BenchmarkPositivesCompany";
import BenchmarkPositivesCountry from "./Reports/BenchmarkPositivesCountry";
import BenchmarkPositivesRegion from "./Reports/BenchmarkPositivesRegion";
import BenchmarkPositivesIndustry from "./Reports/BenchmarkPositivesIndustry";
import BenchmarkPositivesSector from "./Reports/BenchmarkPositivesSector";
import BenchmarkBarriersCompany from "./Reports/BenchmarkBarriersCompany";
import BenchmarkBarriersCountry from "./Reports/BenchmarkBarriersCountry";
import BenchmarkBarriersRegion from "./Reports/BenchmarkBarriersRegion";
import BenchmarkBarriersIndustry from "./Reports/BenchmarkBarriersIndustry";
import BenchmarkBarriersSector from "./Reports/BenchmarkBarriersSector";
import Summary from "./Reports/Summary";
import ExecutiveSummary from "./Reports/ExecutiveSummary";
import ExecutiveSummaryPrint from "./Reports/ExecutiveSummaryPrint"
import ExecutiveReportBrcgs from "./Reports/ExecutiveReportBrcgs";
import Progression from "./Reports/Progression";
import { regionTranslated } from "../Translation/Region";
import RisksComparison from "./Reports/RisksComparison";
import Correlation from "./Reports/Correlation";
import Brcgs from "../Upgrade/Brcgs";
import BenchmarkElementsIndustryBrcgs from "./Reports/BenchmarkElementsIndustryBrcgs";
import SettingsIcon from '@material-ui/icons/Settings';
import PositivesBrcgs from "./Reports/PositivesBrcgs";
import BarriersBrcgs from "./Reports/BarriersBrcgs";
import BarriersComparison from "./Reports/BarriersComparison";
import PositivesComparison from "./Reports/PositivesComparison";
import EnvironmentComparison from "./Reports/EnvironmentComparison";
import CultureType from "./Reports/CultureType";
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import { screenshot } from "../../Utilities/Screenshot";
import BenchmarkElementsRegion from "./Reports/BenchmarkElementsRegion";
import { sectors } from "../../Utilities/Industry";
import { sectorTranslated } from "../Translation/Sector";
import BenchmarkElementsSector from "./Reports/BenchmarkElementsSector";
import ElementRanges from "./Reports/ElementRanges";
import DataExport from "./Reports/DataExport";
import DateFormat from "../DateFormat/DateFormat";
import ElementSummary from "./Reports/ElementSummary";

const useStyles = makeStyles((theme) => ({
    fab: {
        zIndex: 2, // avoid the loadingReport localbackdrop being on top of the button
        position: 'fixed',
        top: theme.spacing(9),
        right: theme.spacing(0.5),
    },
    contentArea: {
        flexGrow: 1,
        padding: theme.spacing(0),
        position: 'relative'
    },
    menuMobileButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        marginBottom: '8px'
    },
    screenshot: {
        [theme.breakpoints.down('lg')]: {
            paddingRight: 40
        },
    }
}));

// Show/hide demographic types based on report type
const surveyTypeShowHide = {
    'demographics': { hide: ['role'] },
    'element-hotspots': { show: ['site'] },
    'element-ranges':  { show: ['site']},
    'element-summary':  { show: ['site']},
    'priority-hotspots': { show: ['site'] },
    'priority-conflict-hotspots': { show: ['site'] },
    'executive-summary': { show: ['site'] },
    'executive-report-brcgs' : {show: [''] },
    'qualitative-comments': { show: ['site'] },
    'response-distribution': { show: ['site'] },
    'correlation': { show: ['site'] },
    'data-export': { show: ['site'] },
};

// Hide the culture-type filter for these report types
const surveyTypeHideCultureType = {
    'correlation': true,
    'qualitative-comments': true,
    'demographics': true,
    'risks': true,
    'barriers': true,
    'barriers-comparison': true,
    'positives': true,
    'positives-comparison': true,
    'quality': true,
    'quality-comparison': true,
    'priorities': true,
    'priority-hotspots': true,
    'priority-conflict-hotspots': true,
    'executive-summary': true,
    'risks-comparison': true,
    'priorities-comparison': true,
    'environment': true,
    'environment-comparison': true,
    'culture-type': true,
    'data-export': true,
    'benchmark-positives-company': true,
    'benchmark-positives-industry': true,
    'benchmark-positives-sector': true,
    'benchmark-positives-region': true,
    'benchmark-positives-country': true,
    'benchmark-barriers-company': true,
    'benchmark-barriers-industry': true,
    'benchmark-barriers-sector': true,
    'benchmark-barriers-region': true,
    'benchmark-barriers-country': true,
    'benchmark-quality-company': true,
    'benchmark-quality-industry': true,
    'benchmark-quality-sector': true,
    'benchmark-quality-region': true,
    'benchmark-quality-country': true,
};

export default function SurveyReport() {
    const location = useLocation();
    const intl = useIntl();
    const classes = useStyles();
    let { hash, type } = useParams();
    const { updateNotification } = useContext(NotificationContext);
    const { updateTitle } = useContext(AppBarTitleContext);
    const { user } = useContext(UserContext);
    const screenshotRef = useRef();

    const {types, updateTypes, demographics, updateDemographics, surveyId, updateSurveyId} = useContext(FilterContext);
    const [unusedDemographics, setUnusedDemographics] = useState([]);
    const [showUnusedDemographics, setShowUnusedDemographics] = useState(false);

    const [topLeftButton, setTopLeftButton] = useState(null);
    const handleSetTopLeftButton = (b) => {
        setTopLeftButton(b);
    };

    const [reportData, setReportData] = useState({
        data: {},
        generatedAt: null,
        type: null
    });

    const [reportError, setReportError] = useState(null);
    const [surveyData, setSurveyData] = useState({});
    const [demographicsData, setDemographicsData] = useState([]);
    const [demographicOptionLabels, setDemographicOptionLabels] = useState([]);
    const [loadingReport, setLoadingReport] = useState(false);
    const [previousSurveys, setPreviousSurveys] = useState([]);
    const [loadingPreviousSurveys, setLoadingPreviousSurveys] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState([]);
    const [regionCountries, setRegionCountries] = useState(null);
    const [sectorIndustries, setSectorIndustries] = useState(null);
    const [industry, setIndustry] = useState(null);
    const [industries, setIndustries] = useState([]);
    const [loadingIndustries, setLoadingIndustries] = useState(false);
    const [previousSurvey, setPreviousSurvey] = useState(null);
    const [previousSurveyMultiple, setPreviousSurveyMultiple] = useState([]);
    const [compareMatchingPrevious, setCompareMatchingPrevious] = useState(false);
    const [selectedDemographics, setSelectedDemographics] = useState({});
    const [selectedDemographic, setSelectedDemographic] = useState('');

    const [cancelTok, setCancelTok] = useState(null);

    const [reportMobileOpen, setReportMobileOpen] = useState(false);
    const handleToggleReportMenu = () => {setReportMobileOpen(!reportMobileOpen)};
    const closeReportMenu = () => {setReportMobileOpen(false)};

    const [filterMobileOpen, setFilterMobileOpen] = useState(false);
    const handleToggleFilterMenu = () => {setFilterMobileOpen(!filterMobileOpen)};

    const CancelToken = axios.CancelToken;

    const isMultiplePreviousReport = (reportType) => {
        return -1 !== [
            'progression'
        ].indexOf(reportType)
    };

    const isPreviousReport = (reportType) => {
        return -1 !== [
            'elements-comparison',
            'basic-data-comparison',
            'priorities-comparison',
            'risks-comparison',
            'barriers-comparison',
            'positives-comparison',
            'quality-comparison',
            'environment-comparison',
            'element-summary'
        ].indexOf(reportType);
    };

    const isSelectDemographicReport = (reportType) => {
        return -1 !== ['element-ranges'].indexOf(reportType);
    };

    const isHotspotsReport = (reportType) => {
        return -1 !== [
            'element-hotspots',
            'priority-hotspots',
            'priority-conflict-hotspots'
        ].indexOf(reportType);
    };

    const isCountryReport = (reportType) => {
        return -1 !== [
            'benchmark-country',
            'benchmark-elements-country',
            'benchmark-barriers-country',
            'benchmark-positives-country',
            'benchmark-quality-country'
        ].indexOf(reportType);
    };

    const isRegionReport = (reportType) => {
        return -1 !== [
            'benchmark-region',
            'benchmark-elements-region',
            'benchmark-barriers-region',
            'benchmark-positives-region',
            'benchmark-quality-region'
        ].indexOf(reportType);
    };

    const isIndustryReport = (reportType) => {
        return -1 !== [
            'benchmark-industry',
            'benchmark-elements-industry',
            'benchmark-elements-industry-brcgs',
            'benchmark-quality-industry',
            'benchmark-positives-industry',
            'benchmark-barriers-industry',
            'benchmark-quality-industry',
            'element-summary'
        ].indexOf(reportType);
    };

    const isSectorReport = (reportType) => {
        return -1 !== [
            'benchmark-sector',
            'benchmark-elements-sector',
            'benchmark-quality-sector',
            'benchmark-positives-sector',
            'benchmark-barriers-sector',
        ].indexOf(reportType);
    };

    const isIndustryReportWithBestInClass = (reportType) => {
        return -1 === [
            'benchmark-quality-industry',
            'benchmark-positives-industry',
            'benchmark-barriers-industry'
        ].indexOf(reportType);
    };

    useEffect(() => {
        fetchSurvey(hash);

        return function cleanup() {
            if (cancelTok) {
                console.log('cleanup');
                cancelTok.cancel();
            }
        }
        // eslint-disable-next-line
    }, [type, hash])

    const fetchReport = (uuid, reportType, demographicsFilter = null, typesFilter = null, params = null) => {
        if (cancelTok) {
            console.log('cancelling');
            cancelTok.cancel();
        }
        const currentCancelToken = CancelToken.source();
        setCancelTok(currentCancelToken);
        // Report types that have no data
        if (-1 !== ['definitions', 'ideas', 'upgrade', 'executive-report-brcgs', 'data-export'].indexOf(reportType)) {
            setLoadingReport(false);
            return;
        }

        // Response distribution report uses the same data as elements
        if ('response-distribution' === reportType) {
            reportType = 'elements';
        } else if ('executive-summary-print' === reportType) {
            // Use site filter set in query params for exec summary print report
            const queryParamSiteFilter = new URLSearchParams(location.search).get('demographics[site]');
            if (queryParamSiteFilter) {
                demographicsFilter = {'site': []};
                queryParamSiteFilter.split('|').forEach(s => {
                    demographicsFilter.site.push(s);
                });
            }
            reportType = 'executive-summary';
        }

        if (params === null) {
            params = {};
        }

        if (isPreviousReport(reportType) && previousSurvey && !('previous' in params)) {
            params.previous = previousSurvey.attributes.combinedHash;
        }

        if (isPreviousReport(reportType) && previousSurvey && !('matchingComparison' in params)) {
            params.matchingComparison = compareMatchingPrevious;
        }

        if (isMultiplePreviousReport(reportType) && previousSurveyMultiple.length && !('previousMultiple' in params)) {
            params.previousMultiple = previousSurveyMultiple.map(s => s.attributes.combinedHash).join('|');
        }

        if (isMultiplePreviousReport(reportType) && previousSurveyMultiple.length && !('matchingComparison' in params)) {
            params.matchingComparison = compareMatchingPrevious;
        }

        if (isSelectDemographicReport(reportType) && selectedDemographic && !('demographic' in params)) {
            params.demographic = selectedDemographic;
        }

        const selectedDems = Object.keys(selectedDemographics).filter(demKey => selectedDemographics[demKey]).join('|');
        if (isHotspotsReport(reportType) && selectedDems && !('demographics' in params)) {
            params['demographics'] = selectedDems;
        }

        if (isCountryReport(type) && country.length && !('countries' in params)) {
            params['countries'] = country.map((c) => c.code).join('|');
        }

        if (isIndustryReport(reportType) && industry && !('industry' in params)) {
            params['industry'] = industry.value;
        }

        if (isRegionReport(type) && regionCountries && !('countries' in params)) {
            params['countries'] = regionCountries.countries.join('|');
        }

        console.log('fetchReport:', uuid, reportType, demographicsFilter, typesFilter, params);
        setLoadingReport(true);

        SurveyService.surveyReport(uuid, reportType, demographicsFilter, typesFilter, currentCancelToken.token, params)
        .then(function (response) {
            setReportError(null);
            setReportData({
                data: response.data.data,
                generatedAt: response.data.generatedAt,
                type: response.data.type
            });
            setLoadingReport(false);
        }).catch(function (error) {
            if (error && error.response && error.response.status === 422) {
                const errorMessage = intl.formatMessage({id: 'report.anonymityError', defaultMessage: 'The selected combination of filters does not have enough data to maintain respondent anonymity. Please adjust the filters'});
                updateNotification(true, errorMessage, 'warning');
                // Show error in report area
                setReportError(<Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">{errorMessage}</Alert>);
            } else if (error && error.response && error.response.status === 404) {
                let errorMessage = intl.formatMessage({id: 'report.noDataError', defaultMessage: 'There is no data available for this report'});

                // Slightly different message if there are any active filters
                if (null !== demographicsFilter) {
                    errorMessage = intl.formatMessage({id: 'report.noDataForFiltersError', defaultMessage: 'There is no data available for the selected combination of filters'});
                }
                updateNotification(true, errorMessage, 'warning');
                // Show error in report area
                setReportError(<Alert style={{justifyContent: 'center'}} severity="warning" variant="filled">{errorMessage}</Alert>);
            }
            console.log(error);

            if (!axios.isCancel(error)) {
                setLoadingReport(false);
            }
        });
    };

    const fetchSurvey = (hash) => {
        setReportError(null);
        setLoadingReport(true);
        SurveyService.surveyByHash(hash, null, ['demographic', 'demographic-option-labels'])
        .then(function (response) {
            if (isPreviousReport(type) || isMultiplePreviousReport(type)) {
                fetchPreviousSurveys(response.data.data.relationships.company.id, response.data.data.attributes.combinedHash);
            }

            if (isCountryReport(type)) {
                fetchCountries();
            }

            if (isIndustryReport(type)) {
                fetchIndustries();
            }

            setSurveyData(response.data.data);

            const initSelectedDemographics = {};
            response.data.included.forEach(dem => {
                if (response.data.data.id === surveyId && (dem.attributes.demographic in selectedDemographics)) {
                    initSelectedDemographics[dem.attributes.demographic] = selectedDemographics[dem.attributes.demographic];
                } else {
                    initSelectedDemographics[dem.attributes.demographic] = false;
                }
            });

            setSelectedDemographics(initSelectedDemographics);
            setDemographicsData(response.data.included.filter(inc => inc.type === 'question'));
            setDemographicOptionLabels(response.data.included.filter(inc => inc.type === 'demographic-option-label'));
            updateTitle(response.data.data.relationships && response.data.data.relationships['survey-title'] ? response.data.data.relationships['survey-title'].data.title : response.data.data.attributes.title);
            if (response.data.data.id !== surveyId) {
                console.log('clear filters!', response.data.data.id, surveyId);
                updateTypes(null);
                updateDemographics(null);
                updateSurveyId(response.data.data.id);

                fetchReport(response.data.data.id, type);
            } else {
                fetchReport(response.data.data.id, type, demographicsFilterForReportType(type, demographics, true), cultureTypesFilterForReportType(type, types));
            }

        }).catch(function (error) {
            if (axios.isCancel(error)) {
                return;
            }
            updateNotification(true, intl.formatMessage({id: 'generic.unknownError', defaultMessage: 'An unknown error occurred!'}), 'error');
            setLoadingReport(false);
            console.log(error);
        });
    };

    // Remove any filters that are not used for this report type before sending a request to the report API
    const demographicsFilterForReportType = (reportType, demographics, showNotice=false) => {
        let newDemographics = { ...demographics };
        const unusedDemographics = [];
        if (surveyTypeShowHide[reportType] && surveyTypeShowHide[reportType].hide && surveyTypeShowHide[reportType].hide.length) {
            surveyTypeShowHide[reportType].hide.forEach((demographicToHide) => {
                if (newDemographics[demographicToHide]) {
                    unusedDemographics.push(demographicToHide);
                    delete newDemographics[demographicToHide];
                }
            });
        } else if (surveyTypeShowHide[reportType] && surveyTypeShowHide[reportType].show && surveyTypeShowHide[reportType].show.length) {
            if (demographics) {
                Object.keys(demographics).forEach((key) => {
                    if (-1 === surveyTypeShowHide[reportType].show.indexOf(key)) {
                        unusedDemographics.push(key);
                        delete newDemographics[key];
                    }
                });
            }
        }

        if (showNotice && unusedDemographics.length && null === localStorage.getItem('unusedFilterHideForever')) {
            setUnusedDemographics(unusedDemographics);
            setShowUnusedDemographics(true);
        }

        return newDemographics;
    };

    const cultureTypesFilterForReportType = (reportType, typesFilter) => {
        if (surveyTypeHideCultureType[reportType]) {
            return [];
        } else {
            return typesFilter;
        }
    };

    const handleFilterChange = (demographicsFilter, typesFilter) => {
        updateTypes(typesFilter);
        updateDemographics(demographicsFilter);

        //Don't use demographic/type filters that are not shown for this type of report
        fetchReport(surveyData.id, type, demographicsFilterForReportType(type, demographicsFilter), cultureTypesFilterForReportType(type, typesFilter));
    };

    const reportElement = function(type, reportData) {
        if (reportError) {
            return reportError;
        } else if (!reportData || 0 === Object.keys(reportData).length) {
            return null;
        } else if ('basic-data' === type && 'basic-data' === reportData.type) {
            if ('brcgs-free' === surveyData.attributes.type) {
                return <BrcgsFreeBasicData reportData={reportData} />
            } else if ('ssafe' === surveyData.attributes.type) {
                return <SsafeBasicData reportData={reportData} />
            } else if ('brcgs' === surveyData.attributes.type) {
                return <BrcgsBasicData reportData={reportData} setScreenshotRef={screenshotRef} />
            } else {
                return <BasicData reportData={reportData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
            }
        } else if ('basic-data-comparison' === type && 'basic-data-comparison' === reportData.type) {
            return <BasicDataComparison reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
        } else if ('elements' === type && 'elements' === reportData.type) {
            if ('brcgs-free' === surveyData.attributes.type) {
                return <BrcgsFreeElements reportData={reportData} />
            } else if ('ssafe' === surveyData.attributes.type) {
                return <SsafeElements reportData={reportData} />
            } else {
                return <Elements reportData={reportData} surveyData = {surveyData} setScreenshotRef={screenshotRef} />
            }
        } else if ('elements-brcgs' === type && 'elements-brcgs' === reportData.type) {
            return <BrcgsElements reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('element-hotspots' === type && 'element-hotspots' === reportData.type) {
            return <ElementHotspots reportData={reportData} demographicQuestions={demographicsData} surveyData={surveyData} demographicOptionLabels={demographicOptionLabels} setScreenshotRef={screenshotRef} />
        } else if ('element-ranges' === type && 'element-ranges' === reportData.type) {
            return <ElementRanges reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} filter={demographics} demographic={'' !== selectedDemographic ? selectedDemographic : null} />
        } else if ('demographics' === type && 'demographics' === reportData.type) {
            return <Demographics reportData={reportData} demographicQuestions={demographicsData} demographicOptionLabels={demographicOptionLabels} setScreenshotRef={screenshotRef} />
        } else if ('definitions' === type) {
            return <Definitions surveyType={ surveyData.attributes ? surveyData.attributes.type : null} />
        } else if ('ideas' === type) {
            return <Ideas surveyType={ surveyData.attributes ? surveyData.attributes.type : null} />
        } else if ('response-distribution' === type && 'elements' === reportData.type) { // Response distribution report uses the same data as elements
            return <ResponseDistribution reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('elements-comparison' === type && 'elements-comparison' === reportData.type) {
            return <ElementsComparison reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('risks' === type && 'risks' === reportData.type) {
            return <Risks reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('qualitative-comments' === type && 'qualitative-comments' === reportData.type) {
            return <QualitativeComments reportData={reportData} />
        } else if ('quality' === type && 'quality' === reportData.type) {
            return <Quality reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('quality-comparison' === type && 'quality-comparison' === reportData.type) {
            return <QualityComparison reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('positives' === type && 'positives' === reportData.type) {
            return <Positives reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('positives-brcgs' === type && 'positives-brcgs' === reportData.type) {
            return <PositivesBrcgs reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('positives-comparison' === type && 'positives-comparison' === reportData.type) {
            return <PositivesComparison reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('barriers' === type && 'barriers' === reportData.type) {
            return <Barriers reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('barriers-brcgs' === type && 'barriers-brcgs' === reportData.type) {
            return <BarriersBrcgs reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('barriers-comparison' === type && 'barriers-comparison' === reportData.type) {
            return <BarriersComparison reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('priorities' === type && 'priorities' === reportData.type) {
            return <Priorities reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('priority-hotspots' === type && 'priority-hotspots' === reportData.type) {
            return <PriorityHotspots reportData={reportData} demographicQuestions={demographicsData} surveyData={surveyData} demographicOptionLabels={demographicOptionLabels} setScreenshotRef={screenshotRef} />
        } else if ('priority-conflict-hotspots' === type && 'priority-conflict-hotspots' === reportData.type) {
            return <PriorityConflictHotspots reportData={reportData} demographicQuestions={demographicsData} demographicOptionLabels={demographicOptionLabels} setScreenshotRef={screenshotRef} />
        } else if ('priorities-comparison' === type && 'priorities-comparison' === reportData.type) {
            return <PrioritiesComparison reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('risks-comparison' === type && 'risks-comparison' === reportData.type) {
            return <RisksComparison reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('demographic-comparison' === type && 'demographic-comparison' === reportData.type) {
            return <DemographicComparison reportData={reportData} demographicQuestions={demographicsData} surveyData={surveyData} demographicOptionLabels={demographicOptionLabels} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-company' === type && 'benchmark-company' === reportData.type) {
            return <BenchmarkCompany reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
        } else if ('benchmark-industry' === type && 'benchmark-industry' === reportData.type) {
            return <BenchmarkIndustry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
        } else if ('benchmark-sector' === type && 'benchmark-sector' === reportData.type) {
            return <BenchmarkSector reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
        } else if ('benchmark-country' === type && 'benchmark-country' === reportData.type) {
            return <BenchmarkCountry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
        } else if ('benchmark-region' === type && 'benchmark-region' === reportData.type) {
            return <BenchmarkRegion reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} setTopLeftButton={handleSetTopLeftButton} />
        } else if ('benchmark-elements-industry' === type && 'benchmark-elements-industry' === reportData.type) {
            return <BenchmarkElementsIndustry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-elements-sector' === type && 'benchmark-elements-sector' === reportData.type) {
            return <BenchmarkElementsSector reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-elements-industry-brcgs' === type && 'benchmark-elements-industry-brcgs' === reportData.type) {
            return <BenchmarkElementsIndustryBrcgs reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-elements-country' === type && 'benchmark-elements-country' === reportData.type) {
            return <BenchmarkElementsCountry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-elements-region' === type && 'benchmark-elements-region' === reportData.type) {
            return <BenchmarkElementsRegion reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-elements-company' === type && 'benchmark-elements-company' === reportData.type) {
            return <BenchmarkElementsCompany reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-ssafe' === type && 'benchmark-ssafe' === reportData.type) {
            return <BenchmarkSsafe reportData={reportData} />
        } else if ('benchmark-elements-ssafe' === type && 'benchmark-elements-ssafe' === reportData.type) {
            return <BenchmarkElementsSsafe reportData={reportData} />
        } else if ('benchmark-quality-company' === type && 'benchmark-quality-company' === reportData.type) {
            return <BenchmarkQualityCompany reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-quality-country' === type && 'benchmark-quality-country' === reportData.type) {
            return <BenchmarkQualityCountry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-quality-region' === type && 'benchmark-quality-region' === reportData.type) {
            return <BenchmarkQualityRegion reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-quality-industry' === type && 'benchmark-quality-industry' === reportData.type) {
            return <BenchmarkQualityIndustry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-quality-sector' === type && 'benchmark-quality-sector' === reportData.type) {
            return <BenchmarkQualitySector reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-positives-company' === type && 'benchmark-positives-company' === reportData.type) {
            return <BenchmarkPositivesCompany reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-positives-country' === type && 'benchmark-positives-country' === reportData.type) {
            return <BenchmarkPositivesCountry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-positives-region' === type && 'benchmark-positives-region' === reportData.type) {
            return <BenchmarkPositivesRegion reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-positives-industry' === type && 'benchmark-positives-industry' === reportData.type) {
            return <BenchmarkPositivesIndustry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-positives-sector' === type && 'benchmark-positives-sector' === reportData.type) {
            return <BenchmarkPositivesSector reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-barriers-company' === type && 'benchmark-barriers-company' === reportData.type) {
            return <BenchmarkBarriersCompany reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-barriers-country' === type && 'benchmark-barriers-country' === reportData.type) {
            return <BenchmarkBarriersCountry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-barriers-region' === type && 'benchmark-barriers-region' === reportData.type) {
            return <BenchmarkBarriersRegion reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-barriers-industry' === type && 'benchmark-barriers-industry' === reportData.type) {
            return <BenchmarkBarriersIndustry reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('benchmark-barriers-sector' === type && 'benchmark-barriers-sector' === reportData.type) {
            return <BenchmarkBarriersSector reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('element-summary' === type && 'element-summary' === reportData.type) {
            return <ElementSummary reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('data-export' === type) {
            return <DataExport surveyData={surveyData} filter={demographics} />
        } else if ('executive-summary' === type && 'executive-summary' === reportData.type) {
            return <ExecutiveSummary reportData={reportData} demographicQuestions={demographicsData} surveyData={surveyData} filter={demographics} demographicOptionLabels={demographicOptionLabels} />
        } else if ('executive-summary-print' === type && 'executive-summary' === reportData.type) {
            return <ExecutiveSummaryPrint reportData={reportData} surveyData={surveyData} demographicQuestions={demographicsData} demographicOptionLabels={demographicOptionLabels} />
        } else if ('executive-report-brcgs' === type && ('id' in surveyData)) {
            return <ExecutiveReportBrcgs surveyData={surveyData} />
        } else if ('summary' === type && 'summary' === reportData.type) {
            return <Summary reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('progression' === type && 'progression' === reportData.type) {
            return <Progression reportData={reportData} surveyData={surveyData} setScreenshotRef={screenshotRef} />
        } else if ('correlation' === type && 'correlation' === reportData.type) {
            return <Correlation reportData={reportData} demographicQuestions={demographicsData} surveyCombinedHash={hash} fetchSurvey={fetchSurvey} loadingReport={setLoadingReport} setScreenshotRef={screenshotRef} />
        } else if ('environment' === type && 'environment' === reportData.type) {
            return <Environment reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('environment-comparison' === type && 'environment-comparison' === reportData.type) {
            return <EnvironmentComparison reportData={reportData} setScreenshotRef={screenshotRef} />
        } else if ('culture-type' === type && 'culture-type' === reportData.type) {
            return <CultureType reportData={reportData} />
        } else if ('upgrade' === type) {
            return <Brcgs surveyData={surveyData} />
        }
        return null;
    };

    const fetchCountries = () => {
        setLoadingCountries(true);
        SiteService.countries().then(response => {
            const transCountries = [];
            response.data.data.forEach((countryCode) => {
                transCountries.push({
                    code: countryCode,
                    label: countryTranslated(COUNTRIES[countryCode], intl)
                });
            });
            transCountries.sort((a, b) => (a.label > b.label ? 1 : -1));
            setCountries(transCountries);
        }).catch(error => {
            console.log(error);
        }).then(() => {
            setLoadingCountries(false);
        });
    }

    const fetchIndustries = () => {
        setLoadingIndustries(true);
        SiteService.industries().then(response => {

            const transIndustries = [];
            response.data.data.forEach((industry) => {
                transIndustries.push({
                    value: industry,
                    label: industryTypeTranslated(industry, intl)
                });
            });
            transIndustries.sort((a, b) => (a.label > b.label ? 1 : -1));

            // Add best in class option
            if (isIndustryReportWithBestInClass(type)) {
                transIndustries.unshift({
                    value: 'best-in-class',
                    label: industryTypeTranslated('Best in class (top 10%)', intl)
                });
            }

            setIndustries(transIndustries);
        }).catch(error => {
            console.log(error);
        }).then(() => {
            setLoadingIndustries(false);
        });
    };

    const fetchPreviousSurveys = (companyUuid, currentCombinedHash) => {
        setLoadingPreviousSurveys(true);
        SurveyService.allSurveysByHash(null, null, {company: companyUuid}).then(response => {
            const previous = [];
            response.forEach((prevSurv) => {
                if (prevSurv.attributes.combinedHash !== currentCombinedHash) {
                    previous.push(prevSurv);
                }
            });
            setPreviousSurveys(previous);
        }).catch(error => {
            console.log(error);
        }).then(() => {
            setLoadingPreviousSurveys(false);
        });
    }

    const selectDemographic = (reportType) => {
        if (!isSelectDemographicReport(reportType)) {
            return null;
        } else {
            const demOptions = demographicsData.filter(dem => {
                return -1 !== ['role', 'lengthofservice'].indexOf(dem.attributes.demographic);
            });
            return (<Grid item sm={2}>
                <FormControl style={{ width: '100%', marginBottom: 8 }}>
                    <Select
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            }
                        }}
                        value={selectedDemographic}
                        onChange={(e) => {
                            setSelectedDemographic(e.target.value);
                            fetchReport(surveyData.id, reportType, demographics, types, {demographic: e.target.value ? e.target.value : null});
                        }}
                        style={{ width: '100%' }}
                    >
                        <MenuItem value="">-</MenuItem>
                        {demOptions.map(d => (
                            <MenuItem key={d.id} value={d.attributes.demographic}>
                                <DemographicLabel demographic={d.attributes.demographic ? d.attributes.demographic : d.attributes.title} />
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText><FormattedMessage id="generic.selectDemographic" defaultMessage="Select demographic" /></FormHelperText>
                </FormControl>
            </Grid>);
        }
    };

    const selectHotspotDemographics = (reportType) => {
        if (!isHotspotsReport(reportType)) {
            return null;
        } else {
            return (<Grid item>
                <FormGroup row style={{justifyContent: 'center'}}>
                    {demographicsData.map(dem => {
                        // Can't deselect site
                        if ('site' === dem.attributes.demographic) {
                            return null;
                        }

                        return (<FormControlLabel
                            key={dem.attributes.shortName}
                            label={<DemographicLabel demographic={dem.attributes.shortName ? dem.attributes.shortName : ''} />}
                            style={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderLeft: '5px solid #ccc', paddingRight: '10px'}}
                            control={
                                <Checkbox
                                    color="primary"
                                    style={{ padding: '5px' }}
                                    checked={selectedDemographics[dem.attributes.demographic]}
                                    onChange={() => {
                                        // Fetch report
                                        const params = [];
                                        Object.keys(selectedDemographics).forEach(demKey => {
                                            if (demKey === dem.attributes.demographic && !selectedDemographics[demKey]) {
                                                params.push(demKey);
                                            } else if (demKey !== dem.attributes.demographic && selectedDemographics[demKey]) {
                                                params.push(demKey);
                                            }
                                        });
                                        fetchReport(surveyData.id, reportType, demographics, types, {demographics: params.length ? params.join('|') : null});

                                        // Update checkbox state
                                        setSelectedDemographics(prevState => ({
                                            ...prevState,
                                            [dem.attributes.demographic]: !prevState[dem.attributes.demographic]
                                        }));
                                    }}
                                />
                            }
                        />);
                    })}
                </FormGroup>
            </Grid>);
        }
    };

    const selectIndustry = (reportType) => {
        if (!isIndustryReport(reportType)) {
            return null;
        } else {
            return (<Grid item sm={4}>
                <Autocomplete
                    fullWidth={true}
                    options={industries}
                    loading={loadingIndustries}
                    value={!isIndustryReportWithBestInClass(reportType) && industry && industry.value === 'best-in-class' ? null : industry} // Hide best-in-class if not supported
                    getOptionLabel={(option) => option.label}
                    onChange={(event, selected) => {
                        setIndustry(selected);
                        fetchReport(surveyData.id, reportType, demographics, types, {industry: selected ? selected.value : null});
                    }}
                    getOptionSelected={(option, value) => option.value === value.value}
                    renderOption={(props, option) => {
                        return (<span {...props}>
                            {props.value === 'best-in-class' ? <strong>{props.label}</strong> : props.label}
                        </span>);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{marginTop:0}}
                            margin="normal"
                            label={intl.formatMessage({id: 'benchmarkIndustryReport.selectIndustry', defaultMessage: 'Select industry...'})}
                            required={true}
                            helperText={intl.formatMessage({id: 'benchmarkIndustryReport.blankComparesToAllIndustries', defaultMessage: 'Blank compares to all industries'})}
                        />
                    )}
                />
            </Grid>);
        }
    };

    const selectSector = (reportType) => {
        if (!isSectorReport(reportType)) {
            return null;
        } else {
            const sectorOptions = [];
            const allSectors = sectors();
            for (const sect in allSectors) {
                sectorOptions.push({
                    name: sectorTranslated(sect, intl),
                    industries: allSectors[sect],
                });
            }

            // Sort by name
            sectorOptions.sort((a,b) => a.name > b.name ? 1 : -1);

            return (<Grid item sm={3}>
                <Autocomplete
                    fullWidth={true}
                    options={sectorOptions}
                    value={sectorIndustries}
                    getOptionLabel={(opt) => opt.name}
                    onChange={(event, selected) => {
                        setSectorIndustries(selected);
                        fetchReport(surveyData.id, reportType, demographics, types, {industry: selected ? selected.industries.join('|') : null});
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{marginTop:0}}
                            label={intl.formatMessage({id: 'benchmarkSectorReport.selectSector', defaultMessage: 'Select sector'})}
                            helperText={intl.formatMessage({id: 'benchmarkSectorReport.blankComparesToAllSectors', defaultMessage: 'Blank compares to all sectors'})}
                        />
                    )}
                />
            </Grid>);
        }
    };

    const selectRegion = (reportType) => {
        if (!isRegionReport(reportType)) {
            return null;
        } else {
            const regions = [];
            for (const regionName in REGIONS) {
                regions.push({
                    name: regionTranslated(regionName, intl),
                    countries: REGIONS[regionName]
                });
            }
            return (<Grid item sm={3}>
                <Autocomplete
                    fullWidth={true}
                    options={regions}
                    value={regionCountries}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, selected) => {
                        setRegionCountries(selected);
                        fetchReport(surveyData.id, reportType, demographics, types, {countries: selected ? selected.countries.join('|') : null});
                    }}
                    getOptionSelected={(option, value) => option.name === value.name}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{marginTop:0}}
                            label={intl.formatMessage({id: 'benchmarkRegionReport.selectRegion', defaultMessage: 'Select region'})}
                            helperText={intl.formatMessage({id: 'benchmarkRegionReport.blankComparesToAllRegions', defaultMessage: 'Blank compares to all regions'})}
                        />
                    )}
                />
            </Grid>);
        }
    };

    const selectCountry = (reportType) => {
        if (!isCountryReport(reportType)) {
            return null;
        } else {
            return (<Grid item sm={3}>
                <Autocomplete
                    fullWidth={true}
                    options={countries}
                    loading={loadingCountries}
                    value={country}
                    multiple={true}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, selected) => {
                        setCountry(selected);
                        fetchReport(surveyData.id, reportType, demographics, types, {countries: selected.length ? selected.map((c) => c.code).join('|') : null});
                    }}
                    getOptionSelected={(option, value) => option.code === value.code}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{marginTop:0}}
                            margin="normal"
                            required={true}
                            label={intl.formatMessage({id: 'benchmarkCountryReport.selectCountries', defaultMessage: 'Select country or countries...'})}
                            helperText={intl.formatMessage({id: 'benchmarkCountryReport.blankComparesToAllCountries', defaultMessage: 'Blank compares to all countries'})}
                        />
                    )}
                />
            </Grid>);
        }
    }

    const setMatchingPrevious = (reportType) => {
        if (!isPreviousReport(reportType) && !isMultiplePreviousReport(reportType)) {
            return null;
        } else {
            return (<Grid item>
                <Tooltip title={intl.formatMessage({id: "benchmark.matchingComparisonTooltip", defaultMessage: "If enabled, compares ONLY to matching sites in the comparison survey. If disabled, compares to ALL accessible sites in the comparison survey."})}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compareMatchingPrevious}
                                onChange={() => {
                                        const params = {
                                            previous: null,
                                            matchingComparison: !compareMatchingPrevious
                                        };
                                        if (null !== previousSurvey) {
                                            params.previous = previousSurvey.attributes.combinedHash;
                                        }
                                        fetchReport(surveyData.id, reportType, demographics, types, params);
                                        setCompareMatchingPrevious(!compareMatchingPrevious)
                                    }
                                }
                                color="secondary"
                            />
                        }
                        label={intl.formatMessage({id: 'compareReport.matchingComparison', defaultMessage: 'Matching comparison?'})}
                    />
                </Tooltip>
            </Grid>);
        }
    }

    const selectPrevious = (reportType) => {
        if (isPreviousReport(reportType)) {
            return (<Grid item>
                <Autocomplete
                    style={{ width: 300 }}
                    options={previousSurveys}
                    loading={loadingPreviousSurveys}
                    value={previousSurvey}
                    getOptionLabel={(option) => (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title)}
                    renderOption={(option) => (
                        <div>
                            {option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title}
                            <div>
                                <Typography variant="caption">
                                    {option.attributes.lastRespondentAt ?
                                        <span>{intl.formatMessage({id: 'surveys.lastResponseColumn', defaultMessage: 'Last response'})}: <span style={{fontWeight: 500}}><DateFormat date={option.attributes.lastRespondentAt} /></span></span>
                                    : null }
                                </Typography>
                            </div>
                        </div>
                    )}
                    onChange={(event, selected) => {
                        const params = {
                            previous: null,
                            matchingComparison: compareMatchingPrevious
                        };
                        if (null !== selected) {
                            params.previous = selected.attributes.combinedHash;
                        }
                        fetchReport(surveyData.id, reportType, demographics, types, params);
                        setPreviousSurvey(selected);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{marginTop:0}}
                            margin="normal"
                            label={intl.formatMessage({id: 'compareReport.selectComparisonSurvey', defaultMessage: 'Select comparison survey...'})}
                            required
                        />
                    )}
                />
            </Grid>);
        } else if (isMultiplePreviousReport(reportType)) {
            return (<Grid item>
                <Autocomplete
                    style={{ width: 600 }}
                    options={previousSurveys}
                    multiple={true}
                    loading={loadingPreviousSurveys}
                    value={previousSurveyMultiple}
                    getOptionLabel={(option) => (option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title)}
                    renderOption={(option) => (
                        <div>
                            {option.relationships['survey-title'] ? option.relationships['survey-title'].data.title : option.attributes.title}
                            <div>
                                <Typography variant="caption">
                                    {option.attributes.lastRespondentAt ?
                                        <span>Last response: <span style={{fontWeight: 500}}>{(new Date(option.attributes.lastRespondentAt)).toLocaleDateString('en-gb', {year: 'numeric', month: 'short', day: 'numeric'})}</span></span>
                                    : null }
                                </Typography>
                            </div>
                        </div>
                    )}
                    onChange={(event, selected) => {
                        const params = {
                            previousMultiple: null !== selected && selected.length ? selected.map(s => s.attributes.combinedHash).join('|') : null,
                            matchingComparison: compareMatchingPrevious
                        };

                        fetchReport(surveyData.id, reportType, demographics, types, params);
                        setPreviousSurveyMultiple(selected);
                    }}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{marginTop:0}}
                            margin="normal"
                            label={intl.formatMessage({id: 'compareReport.selectComparisonSurveys', defaultMessage: 'Select comparison surveys...'})}
                            required
                        />
                    )}
                />
            </Grid>);
        }

        // Report has no previous selector
        return null;
    };

    const permissionsHideCultureType = () => {
        // Hide for BRCGS surveys without explicit permission
        if (user && user.role !== 'admin' && surveyData.attributes && surveyData.attributes.type === 'brcgs') {
            if (surveyData.attributes.reports && -1 === surveyData.attributes.reports.indexOf('filter-culture-type')) {
                return true;
            }
        }
        return false;
    };

    const permissionsHideDemographicFilter = () => {
        if (user && user.role !== 'admin' && surveyData.attributes && surveyData.attributes.type === 'brcgs') {
            if (surveyData.attributes.reports && -1 === surveyData.attributes.reports.indexOf('filter-demographic')) {
                return true;
            }
        }
        return false;
    };

    const reportType = reportData.type ? reportData.type : null;
    const printView = location.pathname.endsWith('-print');
    const hideType = type && (surveyTypeHideCultureType[type] || permissionsHideCultureType());
    const hideDemographic = permissionsHideDemographicFilter();
    return(
        <div style={{display: 'flex'}}>
            {printView ? null :
                <ReportMenu
                    demographicQuestions={demographicsData}
                    mobileOpen={reportMobileOpen}
                    handleDrawerToggle={handleToggleReportMenu}
                    closeDrawer={closeReportMenu}
                    hash={hash}
                    reportType={type}
                    reports={surveyData.attributes ? surveyData.attributes.reports : null}
                    surveyType={surveyData.attributes ? surveyData.attributes.type : null}
                    choiceTypes={surveyData.attributes ? surveyData.attributes.choiceTypes : null}
                    commentTypes={surveyData.attributes ? surveyData.attributes.commentTypes : null}
                    rankTypes={surveyData.attributes ? surveyData.attributes.rankTypes : null}
                    riskTypes={surveyData.attributes ? surveyData.attributes.riskTypes : null}
                />
            }
            <div className={classes.contentArea} style={{ minHeight: '90vh', overflowX: 'hidden' }}>
                <LocalBackdrop open={loadingReport} transitionDuration={600}>
                    <CircularProgress color="secondary" size={40} style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        marginTop: -20,
                        marginLeft: -20
                    }} />
                </LocalBackdrop>
                <Hidden smUp implementation="css">
                    { printView ? null :
                        <Box m="auto" p={0}>
                            <Typography variant="h6" align="center">
                                {surveyData.attributes ? (surveyData.relationships['survey-title'] ? surveyData.relationships['survey-title'].data.title : surveyData.attributes.title) : ''}
                            </Typography>
                        </Box>
                    }
                </Hidden>
                { printView ? null :
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={0}>
                        <Grid item sm={6}>
                            <Button
                                size="small"
                                onClick={handleToggleReportMenu}
                                color="secondary"
                                variant="contained"
                                className={classes.menuMobileButton}
                            >
                                <FormattedMessage id="surveyReport.reports" defaultMessage="Reports" />
                            </Button>
                        </Grid>
                        <Grid item sm={6}>
                            <Button
                                size="small"
                                onClick={handleToggleFilterMenu}
                                color="secondary"
                                variant="contained"
                                className={classes.menuMobileButton}
                            >
                                <FormattedMessage id="surveyReport.filters" defaultMessage="Filters" />
                            </Button>
                        </Grid>
                    </Grid>
                }
                <Grid container>
                    {screenshotRef.current || topLeftButton ?
                        <Grid container justifyContent={screenshotRef.current && topLeftButton ? 'space-between' : 'flex-end'} alignItems="center" direction="row">
                            {topLeftButton ?
                                <Grid item>{topLeftButton}</Grid>
                            : null}
                            {screenshotRef.current ?
                                <Grid item>
                                    <Tooltip title="Screenshot">
                                        <IconButton
                                            className={classes.screenshot}
                                            size="small"
                                            color="inherit"
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() => {screenshot(screenshotRef, 'screenshot.png')}}
                                        >
                                            <CameraAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            : null}
                        </Grid>
                    : null}
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-evenly" alignItems="center" direction="row">
                            {selectIndustry(type)}
                            {selectPrevious(type)}
                            {setMatchingPrevious(type)}
                            {selectHotspotDemographics(type)}
                            {selectDemographic(type)}
                            {selectCountry(type)}
                            {selectRegion(type)}
                            {selectSector(type)}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {reportElement(type, reportData)}
                    </Grid>
                </Grid>
            </div>
            { printView ? null :
                <FilterMenu
                    onFilterChange={handleFilterChange}
                    mobileOpen={filterMobileOpen}
                    handleDrawerToggle={handleToggleFilterMenu}
                    demographicQuestions={demographicsData}
                    demographicOptionLabels={demographicOptionLabels}
                    questionTypes={(surveyData.attributes ? surveyData.attributes.questionTypes : [])}
                    surveyType={surveyData.attributes ? surveyData.attributes.type : null}
                    surveyId={(surveyData.id ? surveyData.id : null)}
                    reportType={reportType}
                    filterTypes={types}
                    filterDemographics={demographics}
                    hide={type && surveyTypeShowHide[type] && surveyTypeShowHide[type].hide ? surveyTypeShowHide[type].hide : null}
                    show={type && surveyTypeShowHide[type] && surveyTypeShowHide[type].show ? surveyTypeShowHide[type].show : null}
                    hideType={hideType}
                    hideDemographic={hideDemographic}
                />
            }
            <Dialog
                open={showUnusedDemographics}
                onClose={() => {setShowUnusedDemographics(false)}}
            >
                <DialogTitle><FormattedMessage id="surveyReport.unusedDemographicsTitle" defaultMessage="Please Note" /></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage id="surveyReport.unusedDemographicsNote" defaultMessage="The following filters are not applicable on this report view, any selections made on a previous report are not used for this report:" />
                    </DialogContentText>
                    <ul>
                        {unusedDemographics.map((dem) => <li key={dem}><Typography variant="body1"><DemographicLabel demographic={dem}/></Typography></li>)}
                    </ul>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => {localStorage.setItem('unusedFilterHideForever', true); setShowUnusedDemographics(false); setUnusedDemographics([])}} color="secondary">
                        <FormattedMessage id="surveyReport.unusedDemographicsDoNotShow" defaultMessage="Do not show again" />
                    </Button>
                    <Button variant="outlined" onClick={() => {setShowUnusedDemographics(false); setUnusedDemographics([])}} color="primary" autoFocus>
                        <FormattedMessage id="generic.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
            <Hidden xlUp xsDown>
                {!hideDemographic && !printView ?
                    <Tooltip title="Filters" enterDelay={0}>
                        <div className={classes.fab}>
                            <Fab color="secondary" onClick={handleToggleFilterMenu}>
                                <SettingsIcon />
                            </Fab>
                        </div>
                    </Tooltip>
                : null}
            </Hidden>
        </div>
    );
}

