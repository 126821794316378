import { Box, Checkbox, Collapse, FormControlLabel, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import { BRCGS_CHART_COLORS } from "../../../Constants/BrcgsChartColors";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { useRef, useState } from "react";
import { Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { positiveTranslated } from "../../Translation/Positive";
import BarLazy from "../../Chart/BarLazy";
import Decimal from 'decimal.js';
import { chartColors } from "../../../Utilities/ChartColors";
import CategoryLabel from "../../Translation/CategoryLabel";
import DimensionLabel from "../../Translation/DimensionLabel";
import ElementLabel from "../../Translation/ElementLabel";
import { RGBAToHexA } from "../../../Utilities/Color";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';

export default function Positives(props) {
    const intl = useIntl();
    const chartRef = useRef(null);
    const [showElements, setShowElements] = useState(false);
    const [unit, setUnit] = useState('percentages');
    const [highlightElements, setHighlightElements] = useState(null);
    const [expandElements, setExpandElements] = useState(false);

    if (!props.reportData.data || !props.surveyData.type) {
        return null;
    }

    const isBrcgsSurvey = props.surveyData.attributes.type === 'brcgs';

    const chartData = {
        labels: [],
        datasets: [{
                data: [],
                backgroundColor: [],
                borderColor: [],
                hoverBackgroundColor: [],
                hoverBorderColor: [],
        }],
    };

    if (isBrcgsSurvey) {
        BRCGS_CHART_COLORS.forEach(colors => {
            chartData.datasets[0].backgroundColor.push(colors['backgroundColor']);
            chartData.datasets[0].borderColor.push(colors['borderColor']);
            chartData.datasets[0].hoverBackgroundColor.push(colors['hoverBackgroundColor']);
            chartData.datasets[0].hoverBorderColor.push(colors['hoverBorderColor']);
        })
    }

    const colorAlpha = (color, alpha = '4D') => {
        if (color.startsWith('rgba(')) {
            return RGBAToHexA(color).substring(0,7)+alpha;
        } else if (color.startsWith('#') && color.length === 7) {
            return color+alpha;
        }
        return color;
    };
    const elementGroups = [];
    let max = 0;

    Object.keys(props.reportData.data).forEach(index => {
        if ('other' !== props.reportData.data[index].title.toLowerCase()) {
            const curLabel = positiveTranslated(props.reportData.data[index].title, intl);
            if (-1 !== curLabel.indexOf('(')) {
                chartData.labels.push(curLabel.replace('(', '\n(').split('\n'));
            } else {
                chartData.labels.push(curLabel);
            }
        } else {
            // Others should be multi to save space
            chartData.labels.push([
                intl.formatMessage({id: 'positives.other', defaultMessage: 'Other'}),
                intl.formatMessage({id: 'positives.otherNote', defaultMessage: '(see Qualitative Comments)'}),
            ]);
        }

        let curColor = null;
        if (!isBrcgsSurvey) {
            const c = chartColors('positives', props.reportData.data[index].title);
            chartData.datasets[0].backgroundColor.push(c.backgroundColor);
            chartData.datasets[0].borderColor.push(c.borderColor);
            chartData.datasets[0].hoverBackgroundColor.push(c.hoverBackgroundColor);
            chartData.datasets[0].hoverBorderColor.push(c.hoverBorderColor);
            curColor = {
                dark: c.backgroundColor,
                light: c.hoverBackgroundColor,
                highlight: colorAlpha(c.hoverBackgroundColor)
            };
        } else {
            const colorIndex = index % 2 === 0 ? 0 : 1;
            curColor = {
                dark: BRCGS_CHART_COLORS[colorIndex].backgroundColor,
                light: BRCGS_CHART_COLORS[colorIndex].hoverBackgroundColor,
                highlight: colorAlpha(BRCGS_CHART_COLORS[colorIndex].hoverBackgroundColor)
            };
        }

        if (props.reportData.data[index].elements && props.reportData.data[index].elements.length) {
            elementGroups.push({
                title: props.reportData.data[index].title,
                color: curColor,
                elements: props.reportData.data[index].elements
            })
        }

        if (unit === 'percentages') {
            chartData.datasets[0].data.push(props.reportData.data[index].percentage);
            max = props.reportData.data[index].percentage > max ? props.reportData.data[index].percentage : max;
        } else {
            chartData.datasets[0].data.push(props.reportData.data[index].count)
            max = props.reportData.data[index].count > max ? props.reportData.data[index].count : max;
        }
    });

    const chartOptions = {
        onHover: (event, chartElement) => {
            if (chartElement &&
                chartElement.length === 1 &&
                props.reportData.data[chartElement[0].index].elements &&
                props.reportData.data[chartElement[0].index].elements.length
            ) {
                if (highlightElements !== props.reportData.data[chartElement[0].index].title) {
                    setHighlightElements(props.reportData.data[chartElement[0].index].title);
                }
            } else if (highlightElements !== null) {
                setHighlightElements(null);
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: unit === 'percentages' ? intl.formatMessage({id: 'positives.percent', defaultMessage: 'Percent (%)'}) : intl.formatMessage({id: 'positives.responses', defaultMessage: 'Responses'})
                },
                min: 0,
                max: max + (max/10), // Add a bit of space at the end to make sure datalabels do not overflow
                ticks: {
                    callback: (value, index, values) => {
                        return index === values.length-1 ? '' : value
                    }
                },
            },
            y: {
                title: {
                    display: false
                }
            }
        },
        indexAxis: 'y',
        animation: {
            duration: 0,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        const label = context.label || '';
                        if ('percentages' === unit) {
                            return label + ': ' + context.parsed.x.toFixed(1) + '%';
                        } else {
                            return label + ': ' + context.parsed.x;
                        }

                    }
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                clamp: true,
                offset: 5,
                formatter: (value) => {
                    return value && 'percentages' === unit ? value.toFixed(0)+' %' : value;
                }
            }
        },
    };

    return (
        <Fragment>
            <Box display="flex" justifyContent="center">
                <ToggleButtonGroup
                    value={unit}
                    exclusive
                    onChange={(event, newUnit) => setUnit(newUnit)}
                >
                    <ToggleButton value="percentages" style={{textTransform: 'none'}}>
                        <FormattedMessage id="positives.percentages" defaultMessage="Percentages"/>
                    </ToggleButton>
                    <ToggleButton value="numbers" style={{textTransform: 'none'}}>
                        <FormattedMessage id="positives.numbers" defaultMessage="Numbers"/>
                    </ToggleButton>
                </ToggleButtonGroup>
                <FormControlLabel
                    style={{paddingLeft: 10}}
                    control={
                        <Checkbox
                            checked={showElements}
                            onChange={() => setShowElements(!showElements)}
                            color="secondary"
                        />
                    }
                    label={intl.formatMessage({id: 'generic.relatedElements', defaultMessage: 'Show related elements?'})}
                />
            </Box>
            <div ref={props.setScreenshotRef}>
                <Grid container justifyContent="space-between">
                    <Grid item style={{flex: 1}} xs={showElements ? 8 : 12}>
                        <BarLazy data={chartData} options={chartOptions} dataLabels={true} innerRef={chartRef} />
                    </Grid>
                    {showElements ?
                        <Grid item xs={4}>
                            <TableContainer>
                                <Table size="small" style={{borderSpacing: '0 5px', borderCollapse: 'separate'}}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <FormattedMessage id="generic.element" defaultMessage={"Element"} />
                                                {expandElements ?
                                                    <IconButton size="small" color="inherit" onClick={() => setExpandElements(false)}><UnfoldLessIcon size="small" /></IconButton>
                                                :
                                                    <IconButton size="small" color="inherit" onClick={() => setExpandElements(true)}><UnfoldMoreIcon size="small" /></IconButton>
                                                }
                                            </TableCell>
                                            <TableCell align="center">
                                                <FormattedMessage id="generic.scorePercent" defaultMessage={"Score (%)"}/>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {elementGroups.map((grp, grpIndex) => (
                                        <TableBody key={grp.title}>
                                            {grp.elements.sort((a,b) => parseFloat(a.score) < parseFloat(b.score) ? 1 : -1).map((ele, eleIndex) => {
                                                const collapsible = grp.elements.length > 2 && eleIndex !== 0 && eleIndex !== grp.elements.length-1;
                                                const hideRowStyle = collapsible && !expandElements ? {visibility: 'collapse'} : {};
                                                return (
                                                    <Tooltip key={grpIndex + '-' + eleIndex} title={
                                                        collapsible && !expandElements ? '' :
                                                        <Fragment>
                                                            <FormattedMessage id="generic.category" defaultMessage={"Category"} />: <CategoryLabel category={ele.category} /><br />
                                                            <FormattedMessage id="generic.dimension" defaultMessage={"Dimension"} />: <DimensionLabel dimension={ele.dimension} />
                                                        </Fragment>
                                                    } placement="left">
                                                        <TableRow style={{...{backgroundColor: highlightElements === grp.title ? grp.color.highlight : null }, ...hideRowStyle}}>
                                                                <Fragment>
                                                                    <TableCell style={{borderLeft: '5px solid '+grp.color.dark, borderBottom: '5px solid '+grp.color.dark}}>
                                                                        {collapsible ? <Collapse in={expandElements}>{ele.shortName ? <ElementLabel element={ele.shortName} /> : ele.title}</Collapse> : ele.shortName ? <ElementLabel element={ele.shortName} /> : ele.title}
                                                                    </TableCell>
                                                                    <TableCell align="center" style={{borderBottom: '5px solid '+grp.color.dark, fontWeight: 'bold'}}>
                                                                        {collapsible ? <Collapse in={expandElements}><span title={ele.score}>{Decimal(ele.score).toFixed(1)}</span></Collapse> : <span title={ele.score}>{Decimal(ele.score).toFixed(1)}</span>}
                                                                    </TableCell>
                                                                </Fragment>
                                                        </TableRow>
                                                    </Tooltip>
                                                );
                                            })}
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        </Grid>
                    : null}
                </Grid>
            </div>
        </Fragment>
    );
}
